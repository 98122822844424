import React, { useEffect, useState } from 'react'
import Logo from './Logo'
import { IconButton, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import Magnify from './magnify.png'
import { HEADER_ITEM_TEXT } from './Util'
import SmallLogo from './images/small_logo.png'


const HeaderItem = ({ text, click, maxWidth }) => {
  return (
    <div style={{
      display: 'flex',
        alignItems: 'center',
        fontWeight: 500,
        cursor: 'pointer', fontSize: maxWidth ? 'medium' : 'unset' }} onClick={() => click(text)}>{text}</div>
  )
}

const useStyles = makeStyles(theme => {

    return {
        root: {
            background: 'white',
            zIndex: 3,
            display: 'flex',
            position: 'fixed',
            width: '100%',
            height: 100
        },
        menuButton: {
            margin: theme.spacing(2),
            marginRight: 0,
            padding: 0
        },
        hide: {
            display: 'none',
        },
        searchRoot: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '0 20px',
        },
        searchInput: {
            paddingRight: 29,
            paddingLeft: 10,
            background: `url(${Magnify})9px center/25px no-repeat #fff`,
            backgroundPosition: 'right',
            borderRadius: 50,
            borderWidth: 3,
            border: '1px solid #ccc',
            fontSize: 15,
            boxSizing: 'border-box',
            fontWeight: 300,
        },
        advancedSearch: {
            border: '1px solid #ccc',
            borderRadius: 50,
            backgroundColor: '#3AB8FF',
            color: 'white',
            marginRight: 16,
            fontWeight: 600
        },
        inputFocused: {
            width: '100%'
        },
        headerItems: {
            display: 'flex',
            alignItems: 'center'
        }
    }
})

export default function Header(props){

    const { app, toggleDrawer, search, advancedSearchClicked, maxWidth1025px } = props

    const classes = useStyles()

    const maxWidth600px = useMediaQuery('(max-width: 600px)')

    const [logo, setLogo] = useState('')

    const [inputFocused, setInputFocused] = useState(false)


    useEffect(() => {
        if(app){
            setLogo(app.image)
        }
    }, [app])

    useEffect(() => {
        if(maxWidth600px){
            setLogo(SmallLogo)
        }else{
            if(app) setLogo(app.image)
        }
    }, [maxWidth600px])

    const handleInputFocus = () => {
        setInputFocused(true)
    }

    const handleInputBlur = () => {
        setInputFocused(false)
    }

  const handleKeyDown = (e) => {
    const key = e.key
    if(key !== 'Enter') return

    search(e.target.value)
  }

    const handleClick = (text) => {
        switch(text){
            case HEADER_ITEM_TEXT.ABOUT:
                window.open('https://racc.org/about/', '__blank')
            break
            case HEADER_ITEM_TEXT.OUR_PROGRAMS:
                window.open('https://racc.org/programs/', '__blank')
            break
            case HEADER_ITEM_TEXT.RESOURCES:
                window.open('https://racc.org/resources/', '__blank')
            break
            case HEADER_ITEM_TEXT.ADVANCED_SEARCH:
                advancedSearchClicked()
            break
            default:
                console.error('[Header.js:handleClick]', 'should not be here')
        }
    }

    return (
        <div className={classes.root}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  edge="start"
                  className={clsx(classes.menuButton)}>
                    <MenuIcon />
                 </IconButton>
                <Logo logo={logo} maxWidth600px={maxWidth600px} />
                <div style={{ display: maxWidth1025px ? 'none' : 'flex', justifyContent: 'space-evenly', flexGrow: 1 }}>
                    <HeaderItem text={HEADER_ITEM_TEXT.ABOUT} click={handleClick} maxWidth={maxWidth1025px} />
                    <HeaderItem text={HEADER_ITEM_TEXT.OUR_PROGRAMS} click={handleClick} maxWidth={maxWidth1025px}/>
                    <HeaderItem text={HEADER_ITEM_TEXT.RESOURCES} click={handleClick} maxWidth={maxWidth1025px}/>
                </div>
                <div style={{ display: maxWidth1025px ? 'flex' : 'none', flexGrow: 1 }} />
                <div style={{
                        display: 'flex',
                        alignItems: maxWidth600px ? 'center' : 'unset',
                        justifyContent: maxWidth600px ? 'center' : 'unset'}}>
                    <div className={classes.searchRoot}
                        style={{ maxWidth: maxWidth600px ? 100 : 300, minWidth: maxWidth600px ? 100 : 250 }}>
                        <input
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            onKeyDown={handleKeyDown}
                            type="text"
                            placeholder={maxWidth600px ?  'Search':  'Search for Portland Public Art'}
                            className={clsx(classes.searchInput, inputFocused && classes.inputFocused)}
                            style={{ height: maxWidth600px ? 30 : 60 }} />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <button className={classes.advancedSearch}
                            style={{
                                width: maxWidth600px ? 100 : 170,
                                height: maxWidth600px ? 30 : 60 ,
                                fontSize: maxWidth600px ? '60%' : '100%' }} onClick={() => handleClick(HEADER_ITEM_TEXT.ADVANCED_SEARCH)}>
                            {HEADER_ITEM_TEXT.ADVANCED_SEARCH}
                        </button>
                    </div>
                </div>
        </div>
    )
}
