
// TODO : zoom in on first story

import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import MarkerWrapper from '../main/MarkerWrapper'

import '../main/Map.css'


export default function GoogleMapsSearch(props){


  const [center, setCenter] = useState(props.center)
  const [zoom, setZoom] = useState(4)
  const [popupWindowStory, setPopupWindowStory] = useState(undefined)
  const [indexOfPopupWindowToShow, setIndexOfPopupWindowToShow] = useState(undefined)


  useEffect(() => {

    if(!props.app) return
    setZoom(props.app.mapZoom)
  }, [props.app])

  useEffect(() => {
    if(!props.center) return
    setCenter(props.center)
  }, [props.center])

  useEffect(() => {
    if(!props.storyZoom) return
    setZoom(props.storyZoom)
  }, [props.storyZoom])

  useEffect(() => {
    if(props.reset){
      handleClosePopupWindow()
      setPopupWindowStory(undefined)
    }

  }, [props.reset])

  const handleChangeCenter = (lat, lng) => {
    setCenter({ lat: lat, lng: lng })
  }

  const handleMarkerClick = (selectedIndex, lat, lng) => {
    const s = getStoriesForLocation(lat, lng)
    setPopupWindowStory(s)
    setCenter({ lat: lat, lng: lng })
  }

  const getStoriesForLocation = (lat, lng) => {
    return this.props.stories.find(s => s.location.lat === lat && s.location.lng === lng)
  }

  const handleClosePopupWindow = () => {
    setIndexOfPopupWindowToShow(undefined)
  }

  const handleShowPopupWindow = (index) => {
    setIndexOfPopupWindowToShow(index)
  }
  
    const { app, data, viewStoryClicked, mapStyle }= props
    

    return (
        <div style={{height: '100%', position: 'relative'}}>
            <GoogleMapReact
                onChange={props.onDragend}
                center={center}
                zoom={zoom}
                defaultZoom={4}
                options={{ styles: mapStyle }}>
                {data.map((datum, index) => (
                  <MarkerWrapper 
                    key={index}
                    index={index}
                    indexOfPopupWindowToShow={indexOfPopupWindowToShow}
                    app={app} 
                    datum={datum}
                    lat={datum.location.lat} 
                    lng={datum.location.lng} 
                    viewStoryClicked={viewStoryClicked}
                    markerClick={() => handleMarkerClick(index, datum.lat, datum.lng)}
                    closePopupWindow={handleClosePopupWindow}
                    popupStory={popupWindowStory}
                    changeCenter={handleChangeCenter}
                    showPopupWindow={() => handleShowPopupWindow(index)}
                    placePopupWindowAbove={true} />
                    
                ))}
            </GoogleMapReact>
        </div>
    )
}