import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import FeaturedCollection from './FeaturedCollection'
import Arrow from './Arrow'

const useStyles = makeStyles((theme) => {
  return {
    featuredCollectionText: {
      fontWeight: 900,
      fontSize: 22,
      lineHeight: '1.2',
      letterSpacing: '-.7px',
      marginBottom: 20,
      color: '#333',
      padding: `0 ${theme.spacing(3)}px`,
    },
    wrapperClass: {
      height: 200,
      width: '100%',
    },
    active: {
      backgroundColor: theme.palette.secondary.main,
    },
  }
})

function FeaturedCollections(props) {
  const { featuredCollections, viewCollectionClicked } = props

  const classes = useStyles()

  const [list, setList] = useState([])
  const [arrowsVisible, setArrowsVisible] = useState(false)

  const listRef = useRef(null)

  const itemRef = useRef(null)

  let startX, scrollLeft, isDown

  let mouseMoved = false

  useEffect(() => {
    if (featuredCollections.length > 0) {
      setList(featuredCollections)
    }
  }, [featuredCollections])

  useEffect(() => {
    setArrowsVisible(isOverFlown())
  }, [list])

  const handleMouseDown = (e) => {
    isDown = true
    startX = e.pageX - listRef.current.offsetLeft
    scrollLeft = listRef.current.scrollLeft
    e.preventDefault()
  }

  const handleMouseMove = (e) => {
    if (!isDown) return
    mouseMoved = true
    e.preventDefault()
    const x = e.pageX - listRef.current.offsetLeft
    const walk = (x - startX) * 3 //scroll-fast
    listRef.current.scrollLeft = scrollLeft - walk
  }

  const handleArrowClick = (type) => {
    const width = listRef.current.clientWidth + 40 // offset needed to account for extra padding and margins
    if (type === 'prev') {
      listRef.current.scrollBy({
        top: 0,
        left: -width,
        behavior: 'smooth',
      })
    } else {
      listRef.current.scrollBy({
        top: 0,
        left: width,
        behavior: 'smooth',
      })
    }
  }

  const isOverFlown = () => {
    return listRef.current.scrollWidth > listRef.current.clientWidth
  }

  const handleClickCapture = (e) => {
    if (mouseMoved) {
      e.stopPropagation()
      mouseMoved = false
    }
    isDown = false
  }

  return (
    <div
      style={{
        display: featuredCollections.length > 0 ? 'flex' : 'none',
        flexDirection: 'column',
        minHeight: 300,
        maxHeight: 400,
        marginTop: 20,
      }}
    >
      <div className={classes.featuredCollectionText}>
        Featured Collections:
      </div>
      <div style={{ display: 'flex', height: 300, padding: '0 24px' }}>
        <Arrow type="prev" click={handleArrowClick} visible={arrowsVisible} />
        <ul
          ref={listRef}
          style={{
            display: 'flex',
            listStyleType: 'none',
            overflowX: 'scroll',
            overflowY: 'hidden',
            margin: 0,
            padding: 0,
            minWidth: 190,
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onClickCapture={handleClickCapture}
        >
          {list.map((featuredCollection, index) => {
            return (
              <li
                key={featuredCollection._id}
                ref={itemRef}
                style={{
                  margin: '0 20px',
                  boxSizing: 'border-box',
                  marginLeft: index === 0 ? 0 : 20,
                }}
              >
                <FeaturedCollection
                  featuredCollection={featuredCollection}
                  viewClicked={viewCollectionClicked} />
              </li>
            )
          })}
        </ul>
        <Arrow type="next" click={handleArrowClick} visible={arrowsVisible} />
      </div>
    </div>
  )
}

export default FeaturedCollections
