import React, { useRef } from 'react'
import Arrow from './Arrow'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => {
    return {
        active: {
            backgroundColor: theme.palette.primary.main,
            padding: '5px 20px',
            fontWeight: 600,
            whiteSpace: 'nowrap',
            textDecoration: 'none',
            display: 'inline-block',
            borderRadius: 50,
            boxSizing: 'border-box',
            color: 'white'
        },
        listRoot: {
            display: 'flex',
            listStyleType: 'none',
            margin: 0,
            overflowX: 'hidden',
        },
        listItem: {
            margin: '0 20px',
            boxSizing: 'border-box',
            height: 39,
            display: 'flex',
            alignItems: 'center',
            cursor: 'default',
            fontSize: 16
        },
        inactive: {
            color: 'white',
            whiteSpace: 'nowrap'
        }
    }
    
})




export default function SubCollections(props){


    const { list, selected, select } = props

    const classes = useStyles()

    const listRef = useRef(null)
    const itemRef = useRef(null)

    let startX, scrollLeft, isDown


    const handleArrowClick = (type) => {
        const width = itemRef.current.offsetWidth
        if(type === 'prev'){
            listRef.current.scrollBy({
                top: 0,
                left: -width,
                behavior: 'smooth'
            }) 
        }else{
            listRef.current.scrollBy({
                top: 0,
                left: width,
                behavior: 'smooth'
            })
        }
    }

    const handleMouseDown = (e) => {
        isDown = true
        startX = e.pageX - listRef.current.offsetLeft
        scrollLeft = listRef.current.scrollLeft
    }


    const handleMouseMove = (e) => {
        if(!isDown) return
        e.preventDefault()
        const x = e.pageX - listRef.current.offsetLeft
        const walk = (x - startX) * 3 //scroll-fast
        listRef.current.scrollLeft = scrollLeft - walk
    }

    const handleMouseUp = () => {
        isDown = false
    }


    return (
        <>
        <Arrow type="prev" click={handleArrowClick} visible={list.length > 1}/>
            <div ref={listRef} 
              className={classes.listRoot}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}>
                {list.map(el => {
                    const { title } = el
                    return (
                        <div key={title}
                            ref={itemRef} 
                            onClick={() => select(title)}
                            className={classes.listItem}>
                            <div style={{color: 'white'}}
                                className={selected === title ? classes.active : classes.inactive}>
                                    {title}
                                </div>
                        </div>
                    )
                })} 
            </div>
        <Arrow type="next" click={handleArrowClick} visible={list.length > 1} />
    </>
    )
}