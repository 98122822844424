import React, { useRef, useState, useEffect } from 'react'
import FeaturedCollections from './FeaturedCollections'
import FeaturedStories from './FeaturedStories'
import Stories from './Stories'
import ExploreByMap from './ExploreByMap'
import Discover from './Discover'
import api from '../exhibitApi'


const sortByMostRecentlyAdded = (stories) => {
  return stories.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
}

const sortByRandom = (array) => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex

  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }

  return array
}

const getDiscoverStories = (stories) => {
  stories = sortByRandom(stories)
  return stories
}

export default function StoryFeedMain(props) {
  const {
    app,
    collection,
    collections,
    viewCollectionClicked,
    skip,
    moveToTop
  } = props

  const [featuredCollections, setFeaturedCollections] = useState(collections)
  const [stories, setStories] = useState([])
  const [featuredStories, setFeaturedStories] = useState([])
  const [fiveStories, setFiveStories] = useState([])
  const [discover, setDiscover] = useState([])

  const exhibitId = app._id
  const getPageOfStories = useRef()

  useEffect(() => {
    const pager = api.story().getPager({exhibitId, order: 'descending', limit: 20, featured: false})
    getPageOfStories.current = pager.getPageOfStories
  }, [api, exhibitId])

  useEffect(() => {
    async function fetchFeaturedStories(id) {
      const newStories = await api.story().getFeaturedByExhibitId(id)
      setFeaturedStories(newStories)
    }

    fetchFeaturedStories(exhibitId)
  }, [api, exhibitId])

  useEffect(() => {
    async function fetchStoriesNotFeatured() {
      let newStories = await getPageOfStories.current()
      newStories = sortByMostRecentlyAdded(newStories)
      const five = newStories.slice(0, 5)
      setFiveStories(five)

      const discoverStories = getDiscoverStories(newStories.slice(5))
      setDiscover([...discover, ...discoverStories])
      setStories([...stories, ...newStories])
    }

    fetchStoriesNotFeatured()
  }, [skip, exhibitId])

  useEffect(() => {
    if (collections.length > 0) {
      const featured = collections.filter((c) => c.featured === true)
      setFeaturedCollections(featured)
    }
  }, [collections])

  const handleShowStory = (storyId) => {
    props.history.push(`${props.match.url}/story/${storyId}`)
    moveToTop()
  }

  const handleExploreButtonClicked = () => {
    props.history.push(`${props.match.url}/art-map`)
  }

  return (
    <>
      <FeaturedStories
        featuredCollections={featuredCollections}
        featuredStories={featuredStories}
        showStory={handleShowStory} />

      <FeaturedCollections
        collection={collection}
        featuredCollections={featuredCollections}
        viewCollectionClicked={viewCollectionClicked} />

      <Stories
        stories={fiveStories}
        showStory={handleShowStory}
        orgId={app.orgId} />

      <ExploreByMap exploreClicked={handleExploreButtonClicked} />
      <Discover stories={discover} showStory={handleShowStory} />
    </>
  )
}
