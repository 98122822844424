import React, { useEffect, useState, useRef } from 'react'
import Typography from '@material-ui/core/Typography'
import { withRouter } from 'react-router-dom'
import Image from './popup/Image'
import Video from './popup/Video'
import Audio from './popup/Audio'
import Text from './popup/Text'
import YouTube from './popup/YouTube'
import Carousel from 'nuka-carousel'
import { makeStyles } from '@material-ui/styles'
import BackButton from './popup/back.png'
import DateIcon from './popup/date_icon.png'
import GoogleMaps from './popup/GoogleMaps'
import PortlandMetaData from './popup/metadata/PortlandMetaData'
import Tags from './tags/Tags'
import Lightbox from 'react-image-lightbox'
import { StoryItemPDF as PDF } from '@Pass-It-Down/story-item-react'
import PDFPreviewDialog from './popup/PDFPreviewDialog'
import api from './exhibitApi'

import 'react-image-lightbox/style.css' // This only needs to be imported once in your app
import './StoryPage.css'



const useStyles = makeStyles(theme => {
  return {
    root: {
      position: 'relative',
      marginBottom: 70,
      padding: theme.spacing(3),
    },
    title: {
      fontSize: 32,
      fontFamily: 'Avenir, Avenir Next, \'Montserrat\', \'OpenSans\', Helvetica, Arial, sans-serif',
      lineHeight: 1.3,
      padding: 8,
      fontWeight: 600,
      letterSpacing: '-1px',
      color: theme.palette.primary.main,
      marginBottom: 0,
    },
    arrow: {
      position: 'fixed',
      bottom: -20,
      width: '4em',
      height: '4em',
      left: 0,
      right: 0,
      margin: 'auto',
      color: 'white'
    },
    back: {
      position: 'fixed',
      bottom: '20%',
      width: 150,
      paddingTop: '10%',
      background: `url(${BackButton})left center/contain no-repeat`
    },
    description: {
      color: 'rgb(107, 106, 103)',
      letterSpacing: 'normal',
      padding: '0 8px 10px',
      fontWeight: 400,
      fontSize: 20
    },
    locationSection: {
      display: 'flex',
      flexDirection: props => props.isMobile ? 'column' : 'unset',
      color: theme.palette.secondary.main,
      padding: '15px 1%'
    },
    dateIcon: {
      background: `url(${DateIcon})left top/22px no-repeat`,
      paddingLeft: 28,
      marginRight: 15,
      fontWeight: 600,
      letterSpacing: '-.8px',
      fontSize: 15,
      height: 22,
      color: 'inherit'
    },
    location: {
      marginTop: props => props.isMobile ? 10 : 0,
      fontFamily: 'Avenir, Avenir Next, \'Montserrat\'',
      color: '#666'
    }
  }
})


function StoryPage(props) {
  const { app, isMobile, tagClick } = props
  const mapStyleName = app.mapStyle

    const classes = useStyles(props)

    const [assets, setAssets] = useState([])
    const [tags, setTags] = useState([])
    const dialogContentRef = useRef(null)
    const stackedRef = useRef(null)

    const [slideCurrent, setSlideCurrent] = useState(0)
    const [keyboardDownClicked, setKeyboardDownClicked] = useState(false)
    const [arrowVisible, setArrowVisible] = useState(true)
    const [story, setStory] = useState({
      description: ''
    })
    const [dateToShow] = useState('')
    const [mapStyle, setMapStyle] = useState([])
    const [lightboxOpen, setLightboxOpen] = useState(false)
    const [lightboxImage, setLightBoxImage] = useState('')
    const [openPDFPreviewDialog, setOpenPDFPreviewDialog] = useState(false)
    const [PDFLink, setPDFLink] = useState('')

  const storyId = props.match.params.storyId


  useEffect(() => {
    async function getStoryAssets(id) {
      const story =  await api.story().getById(id)
      setStory(story)
      setAssets(story.assets)
    }

    async function getTags(id) {
      const tags =  await api.story().getTagsById(id)
      setTags(tags)
    }

    getStoryAssets(storyId)
    getTags(storyId)

  }, [api, storyId])

  useEffect(() => {
    async function fetchMapStyle(name){
      const style = await api.mapStyle().getByName(name)
      setMapStyle(style)
    }

    fetchMapStyle(mapStyleName)
  }, [api, mapStyleName])


  const isImage = () => {
    const asset = assets[slideCurrent]
    if (!asset) return false
    return asset.isImage
  }

  useEffect(() => {
    if (assets.length > 0 && !isImage()) {
      setArrowVisible(false)
    } else {
      setArrowVisible(true)
    }
  }, [slideCurrent])

  // temporary height issue fix: https://github.com/FormidableLabs/nuka-carousel/issues/18#issuecomment-236455698
  const afterSlide = (currentSlide) => {
    const list = window.document.querySelector('.slider-list')
    const nextSlide = list.childNodes[currentSlide]
    list.style.height = nextSlide.offsetHeight + 'px'

    if (keyboardDownClicked) {
      setKeyboardDownClicked(false) // reset
      setArrowVisible(true)
    }

    setSlideCurrent(currentSlide)
  }

    const handleScroll = () => {
      // TODO: [P3MVP-468] function is never called
      console.log('handle scroll called')
      const scrollTop = dialogContentRef.current.scrollTop
      if(scrollTop === 0){
        setKeyboardDownClicked(false)
        if(shouldShowArrow()){
          setArrowVisible(true)
        }

      }else{
        if(arrowVisible) setArrowVisible(false)
      }
    }

    const shouldShowArrow = () => {
      return isImage()
    }

    const handleShowLightbox = (imageLink) => {
      setLightBoxImage(imageLink)
      setLightboxOpen(true)
    }

    const handleShowPDF = (link) => {
      setPDFLink(link)
      setOpenPDFPreviewDialog(true)
    }

    const handleClosePDFPreviewDialog = () => {
      setPDFLink('')
      setOpenPDFPreviewDialog(false)
    }


    return (
      <>
        {lightboxOpen &&
          <Lightbox
            mainSrc={lightboxImage}
            onCloseRequest={() => setLightboxOpen(false)} /> }
        <div
          className={classes.root}
          onScroll={handleScroll}
          ref={dialogContentRef}>
                <div>
                  <Typography classes={{
                      root: classes.title
                    }} gutterBottom style={{marginTop: 16}}>
                        {story.title}
                  </Typography>

                  <div style={{ display: story.description ? 'block' : 'none', padding: '15px 1%', boxSizing: 'border-box'}}>
                    <span className={classes.description}>
                      {story.description}
                    </span>
                  </div>
                  <div className={classes.locationSection}>
                    <span className={classes.dateIcon}>{dateToShow}</span>
                    <div style={{display: story.locationName === '' ? 'none' : 'block'}} className={classes.location}> - {story.locationName}</div>
                  </div>
                </div>
                {story.storyTemplate === 'Gallery' &&
                <div>
                <Carousel
                  style={{minHeight: 500}}
                  afterSlide={afterSlide}
                  withoutControls={assets.length === 1}>
                  {assets.map((asset, index) => {
                    switch(asset.type){
                      case 'IMAGE':
                        return (
                          <Image
                            key={index}
                            asset={asset}
                            isGallery={true}
                            keyboardDownClicked={keyboardDownClicked}
                            currentSlide={slideCurrent}
                            index={index}
                            showLightbox={handleShowLightbox}/>
                        )

                      case 'VIDEO':
                        return (
                          <Video key={index} height={500} asset={asset} />
                        )

                      case 'YOUTUBE':
                        return (
                          <YouTube key={index} height={500} asset={asset} />
                        )

                      case 'AUDIO':
                        return (
                          <Audio key={index} height={500} asset={asset} />
                        )
                      case 'PDF':
                        return (
                          <PDF key={index} asset={asset} showPDF={handleShowPDF} />
                        )
                      default:
                        console.error('should not be here')
                        return null
                    }
                  })}
                </Carousel>
                {assets.filter(asset => asset.type === 'TEXT').map((asset, index) => (
                  <div key={index} style={{padding: '15px 1%', boxSizing: 'border-box'}}>
                    <Text asset={asset} />
                  </div>
                ))}
              </div>}

              {story.storyTemplate === 'Stacked' &&
                <ul ref={stackedRef} style={{padding: 0}}>
                  {assets.map((asset, index) => {
                    switch(asset.type){
                      case 'IMAGE':
                        return (
                          <li key={index} style={{listStyleType: 'none', margin: '16px 0'}}>
                            <Image
                              asset={asset}
                              height={500}
                              isMobile={isMobile}
                              showLightbox={handleShowLightbox}/>
                          </li>

                        )

                      case 'VIDEO':
                        return (
                          <li key={index} style={{listStyleType: 'none', margin: '16px 0'}}>
                            <Video height={500} asset={asset} />
                          </li>
                        )

                      case 'YOUTUBE':
                        return (
                          <li key={index} style={{listStyleType: 'none', margin: '16px 0'}}>
                             <YouTube key={index} height={500} asset={asset} />
                          </li>
                        )

                      case 'AUDIO':
                        return (
                          <li key={index} style={{listStyleType: 'none', margin: '16px 0'}}>
                            <Audio height={500} asset={asset} />
                          </li>
                        )

                        case 'TEXT':
                        return (
                          <li key={index} style={{listStyleType: 'none', padding: '15px 1%', boxSizing: 'border-box'}}>
                            <Text asset={asset} />
                          </li>
                        )
                        case 'PDF':
                          return (
                            <li key={index} style={{listStyleType: 'none', padding: '15px 1%', boxSizing: 'border-box'}}>
                              <PDF asset={asset} showPDF={handleShowPDF} />
                            </li>
                          )

                      default:
                        return (
                          <li key={index} style={{listStyleType: 'none', margin: '16px 0'}}>
                            <Image asset={asset} />
                          </li>

                        )
                    }
                  })}
                  </ul>
                }


                <GoogleMaps
                  app={app}
                  center={story.location}
                  location={story.location}
                  mapStyle={mapStyle} />

                {app.viewTagsPublicly && tags.length > 0 && <Tags tags={tags} click={tagClick}/>}

                {story.portlandMetaData &&
                  <PortlandMetaData metaData={story.portlandMetaData}/>}

                <PDFPreviewDialog
                  open={openPDFPreviewDialog}
                  PDFLink={PDFLink}
                  close={handleClosePDFPreviewDialog} />
        </div>
      </>
    )
}

export default withRouter(StoryPage)
