import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    root: {
        fontWeight: 600,
        margin: '5px 2% 5px 0',
        padding: '3px 10px',
        fontSize: 16,
        letterSpacing: '-.8px',
        boxSizing: 'border-box',
        cursor: 'pointer'
    }
}))

export default function Filter(props){

    const classes = useStyles()
    const theme = useTheme()

    const { filterName, click, selectedFilter } = props

    const getColor = () => {
        return selectedFilter === filterName ? theme.palette.primary.main : theme.palette.secondary.main
    }

    return (
        <div className={classes.root} style={{ color: getColor() }} onClick={click}>{filterName}</div> 
    )
}