import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import Collections from './CollectionsPage/Collections'
import SubPortland from './SubPortland'
import Footer from './Footer'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import Hidden from './Hidden'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import MobileContext from './context/MobileContext'
import AdvancedSearch from './advanced-search/AdvancedSearch'
import api from './exhibitApi'

const theme = createTheme({
  palette: {
    primary: blue,
  },
})

function Portland(portlandProps) {
  const { appId } = portlandProps

  const isMobile = useMediaQuery('(max-width: 1025px)')

  const [collections, setCollections] = useState([])
  const [app, setApp] = useState(undefined)
  const [appHidden, setAppHidden] = useState(false)
  const [appLoaded, setAppLoaded] = useState(false)

  useEffect(() => {
    async function fetchApp(id) {
      const app = await api.exhibit().getShallowById(id)

      if (app.status === 'HIDDEN') {
        setAppHidden(true)
      } else {
        setApp(app)
        document.title = app.name
        setCollections(app.collections)
      }
      setAppLoaded(true)
    }

    fetchApp(appId)
  }, [appId, api])


  const handleStoryClicked = (storyId) => {
    portlandProps.history.push(`${portlandProps.match.url}/story/${storyId}`)
  }

  const handleAdvancedSearchClicked = () => {
    portlandProps.history.push(`${portlandProps.match.url}/advanced-search`)
  }

  const handleShowStory = (storyId) => {
    portlandProps.history.push(`${portlandProps.match.url}/story/${storyId}`)
  }

  return (
    <>
      {appLoaded && appHidden && <Hidden />}
      {appLoaded && !appHidden && (
        <MuiThemeProvider
          theme={
            app
              ? createTheme({
                  palette: {
                    primary: { main: app.mainColor },
                    secondary: { main: app.secondaryColor },
                  },
                })
              : theme
          }
        >
          <MobileContext.Provider value={{ isMobile: isMobile }}>
            <Switch>
              <Route
                exact
                path={`${portlandProps.match.url}/advanced-search`}
                render={(props) => (
                  <AdvancedSearch
                    {...props}
                    app={app}
                    viewStoryClicked={(story) => handleShowStory(story._id)}
                  />
                )}
              />

              <Route
                exact
                path={`${portlandProps.match.url}/collections`}
                render={(props) => (
                  <Collections
                    {...props}
                    app={app}
                    collections={collections}
                    storyClicked={handleStoryClicked}
                  />
                )}
              />
              {/* TODO: Why isn't appid passed as part of react router in params? */}
              <Route
                path={portlandProps.match.url}
                render={(props) => (
                  <SubPortland
                    {...props}
                    app={app}
                    appId={appId}
                    collections={collections}
                    advancedSearchClicked={handleAdvancedSearchClicked}
                  />
                )}
              />
            </Switch>
          </MobileContext.Provider>

          <Footer app={app} {...portlandProps} />
        </MuiThemeProvider>
      )}
    </>
  )
}

export default Portland
