import React, { useState, useEffect, useRef } from 'react'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CommentIcon from './comment_icon.png'
import Caption from './Caption'
import PicExpand from './PicExpand'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'

import './Image.css'

const makeAnnotationStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    minHeight: 75,
    boxSizing: 'border-box',
    color: '#666',
    fontFamily: 'Avenir, Avenir Next, \'Montserrat\', \'OpenSans\', Helvetica, Arial, sans-serif',
    lineHeight: 1.3,
    fontSize: 18,
  },
  commentIcon: {
    width: 50,
    height: 50,
    backgroundSize: 26,
    backgroundImage: `url(${CommentIcon})`,
    backgroundColor: 'rgba(0,0,0,.9)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    borderRadius: '100%',
    border: '2px solid #fff',
    [theme.breakpoints.down('xs')]: {
      width: 25,
      height: 25,
      backgroundSize: 11,
    },
  },
  text: {
    padding: '5px 5px 5px 16px',
    left: 50,
    textAlign: 'left',
    top: 0,
    maxWidth: 300,
    minWidth: 130,
    borderRadius: 2,
    background: 'rgba(0,0,0,0.6)',
    color: '#fff',
    position: 'absolute',
    zIndex: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      left: 28,
    },
  },
}))

const Annotation = (props) => {
  const classes = makeAnnotationStyles()

  const { annot, annotationVisible } = props

  const [showText, setShowText] = useState(false)

  const handleCommentIconClicked = () => {
    if (showText) {
      setShowText(false)
    } else {
      setShowText(true)
    }
  }

  return (
    <div
      className={classes.root}
      style={{
        display: annotationVisible ? 'block' : 'none',
        left: annot.x,
        top: annot.y,
      }}
      onMouseEnter={() => setShowText(true)}
      onMouseLeave={() => setShowText(false)}
    >
      <div className={classes.commentIcon} onClick={handleCommentIconClicked} />
      <div className={clsx(showText ? 'fadeIn' : 'fadeOut', classes.text)}>
        {annot.text}
      </div>
    </div>
  )
}

export default function Image(props) {
  const {
    asset,
    currentSlide,
    keyboardDownClicked,
    index,
    isGallery,
    showLightbox,
  } = props

  const dummyRef = useRef(null)

  const [annotationsVisible, setAnnotationsVisibility] = useState(true)

  // https://github.com/FormidableLabs/nuka-carousel/issues/265#issuecomment-403350905
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 0)
  }, [])

  useEffect(() => {
    if (keyboardDownClicked && currentSlide === index && isGallery) {
      dummyRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [keyboardDownClicked])

  const toggleAnnotationsVisibility = () => {
    setAnnotationsVisibility(!annotationsVisible)
  }

  const handlePicExpandClick = () => {
    showLightbox(asset.link)
  }

  return (
    <Card>
      <CardActionArea style={{ position: 'relative' }}>
        <PicExpand click={handlePicExpandClick} />
        <img
          alt={asset.name}
          src={asset.link}
          width={'100%'}
          style={{ objectFit: 'cover', height: '100%', width: '100%' }}
        />
        {asset.caption && (
          <Caption asset={asset} click={toggleAnnotationsVisibility} />
        )}
        {asset.annotations &&
          asset.annotations.map((annot, index) => {
            return (
              <Annotation
                key={index}
                annot={annot}
                annotationVisible={annotationsVisible}
              />
            )
          })}
        <div
          ref={dummyRef}
          style={{ position: 'absolute', bottom: 0, height: 10 }}
        />
      </CardActionArea>
    </Card>
  )
}
