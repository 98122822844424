import { createSlice } from '@reduxjs/toolkit'
import { ExhibitionClient } from '@Pass-It-Down/exhibition-client'

const url = process.env.REACT_APP_SERVER_URL
const api = new ExhibitionClient({ url })

export const exhibitSlice = createSlice({
  name: 'exhibit',
  initialState: {
    exhibit: null,
    exhibitId: null,
    exhibitName: '',
    exhibitHidden: false,
    exhibitLoaded: false,
    exhibitCollections: []
  },
  reducers: {
    setExhibitId: (state, action) => {
      state.exhibitId = action.payload
    },
    setExhibit: (state, action) => {
      state.exhibit = action.payload
      state.exhibitName = action.payload.name
      state.exhibitCollections = action.payload.collections
      state.exhibitLoaded = true
    },
    setExhibitHidden: (state, action) => {
      state.exhibitHidden = action.payload
    },
    setExhibitCollections: (state, action) => {
      state.collections = action.payload
    }
  }
})

export const { setExhibitId, setExhibit, setExhibitHidden, setExhibitCollections } = exhibitSlice.actions


export const loadExhibit = (exhibitId) => async (dispatch, getState) => {
  if (getState().exhibit.exhibitLoaded) return

  dispatch(setExhibitId(exhibitId))
  const exhibit = await api.exhibit().getById(exhibitId)
  if (exhibit.status === 'HIDDEN') {
    dispatch(setExhibitHidden(true))
  } else {
    dispatch(setExhibit(exhibit))
  }
}

export const selectedExhibitId = state => state.exhibit.exhibitId
export const selectedExhibit = state => state.exhibit.exhibit
export const selectedExhibitLoaded = state => state.exhibit.exhibitLoaded
export const selectedExhibitHidden = state => state.exhibit.exhibitHidden

export const selectedExhibitCollections = state => state.exhibit.exhibitCollections

export default exhibitSlice.reducer