import React from 'react'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import ReactPlayer from 'react-player'
import Caption from './Caption'

const useStyles = makeStyles({
    card: {
      position: 'relative',
      width: '100%',
      height: 500,
    }
  })

function Video(props){

    const classes = useStyles()
    const { asset } = props


    const handleVideoReady = () => {
      window.dispatchEvent(new Event('resize'))
    }

    return(
        <Card className={classes.card}>
            <ReactPlayer 
              width={'100%'} 
              url={asset.link} 
              onReady={handleVideoReady}
              controls
              config={
                { 
                  file: { 
                    attributes: {
                      controlsList: 'nodownload' // prevent users from downloading videos https://github.com/CookPete/react-player/issues/323#issuecomment-364357541
                  }
              }}} />
            <Caption asset={asset} />
        </Card>
    )
}

export default Video