import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import AudiotrackIcon from '../headphones-monochrome.png'
import PDFIcon from '../pdf_icon.jpg'
import { isMobile } from 'react-device-detect'
import api from '../exhibitApi'

import './Story.css'
import '../queries.css'

const useStyles = makeStyles((theme) => {
  return {
    image: {
      height: '100%',
      width: '95%',
      margin: 'auto',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      '&:hover': {
        boxShadow: '0 0 8px rgba(0,0,0,.3)',
        cursor: 'pointer',
      },
    },
    date: {
      color: theme.palette.primary.main,
    },
    title: {
      color: 'white',
      position: 'absolute',
      bottom: 10,
      fontWeight: 600,
      fontFamily: 'Montserrat',
      fontSize: 30,
    },
  }
})

export default function Story(props) {
  const classes = useStyles()

  const { story, click } = props

  const [image, setImage] = useState('')

  useEffect(() => {
    if (!story.image) return

    if (story.image.includes('.mp3')) {
      setImage(AudiotrackIcon)
    } else if (story.image.includes('.pdf')) {
      setImage(PDFIcon)
    } else {
      let width, height

      if (isMobile) {
        width = 750
        height = 750
      } else {
        width = 1000
        height = 1000
      }

      const link = api.image().imageUrl(story.image, width, height)
      setImage(link)
    }

  }, [story.image])

  return (
    <div className={'story'} onClick={() => click(story._id)}>
      <div
        className={classes.image}
        style={{ background: `url(${image})center center/cover no-repeat` }}
      />
      <div className={[classes.date, 'date'].join(' ')}>
        {story.formatted_start_date}
      </div>
    </div>
  )
}
