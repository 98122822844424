import { createSelector } from '@reduxjs/toolkit'

const allStories = state => state.stories.allStories

export const storyMapSelector = createSelector(
    [ allStories ],
    (stories) => {
        const locationNull = (location) => {
            return location.lat === null || location.lat === undefined ||
              location.lng === null|| location.lng === undefined
          }
      
        const locationAlreadyExists = (arr, location) => {
        for (let index = 0; index < arr.length; index++) {
            const l = arr[index].location
            if(l.lat === location.lat && l.lng === location.lng){
            return index
            }
        }
        return -1
        }
    
    
        const getUniqueLocations = (stories) => {
    
        const arr = []
    
        for (let index = 0; index < stories.length; index++) {
            const story = stories[index]
            const location = story.location
            if(locationNull(location)) continue
            const locationIndex = locationAlreadyExists(arr, location)
            if(locationIndex === -1){ // location does not exist
            const storiesArray = []
            storiesArray.push(story)
            arr.push({
                location: {...location},
                stories: storiesArray
            })
            }else{
            arr[locationIndex].stories.push(story)
            }
        }
    
        return arr
    
        }

        return getUniqueLocations(stories)
    }
)