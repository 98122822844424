import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '8px',
    objectFit: 'cover',
    objectPosition: 'center',
  },
})

export default function CollectionCoverPhoto(props) {
  const classes = useStyles()

  const { image, title, onImageError } = props

  return (
    <img
      src={image}
      onError={onImageError}
      className={classes.root}
      width={'100%'}
      height={'100%'}
      alt={title}
    />
  )
}
