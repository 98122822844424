import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useProgressDialogStyles = makeStyles(theme =>({
  progress: {
    margin: 2 * theme.spacing(1),
  },
}))

export default function ProgressDialog(props) {

  const { show, left, right } = props

  const classes = useProgressDialogStyles()

  return (
    <div style={{display: show ? 'block' : 'none', position: 'fixed', bottom: 60, left: left ? 20 : 'unset', right: right ? 20 : 'unset'}}>
      <CircularProgress className={classes.progress} />
    </div>
  )
}

ProgressDialog.defaultProps = {
  show: false
}
