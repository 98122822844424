import React from 'react'


export default function Logo(props){

    const { logo, maxWidth600px } = props

    return (
        <div style={{
            backgroundImage: `url(${logo})`, 
            backgroundPosition: 'center',
            backgroundSize: 'contain', 
            backgroundRepeat: 'no-repeat',
            margin: 10,
            padding: '0 10px', 
            width: maxWidth600px ? 20 : 150, 
            height: maxWidth600px ? 50 : 80 }} />
    )
}