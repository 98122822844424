import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import AudiotrackIcon from  './headphones-monochrome.png'
import PDFIcon from './pdf_icon.jpg'
import Carousel from 'nuka-carousel'
import CarouselItem from './CarouselItem'
import CloseButton from '@material-ui/icons/Close'
import MobileContext from '../context/MobileContext'

// TODO: I don't need this in LFLB; find out why close button is tiny when 
// this file is not included

import './PopupWindow.css'


const useStyles = makeStyles(theme => ({
        root: {
            boxSizing: 'border-box',
            overflow: 'hidden',
            top: 0,
            left: 0,
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 2px 7px 1px rgba(0,0,0,0.3)',
            zIndex: 1000,
            position: 'absolute !important'
        },
        arrow: {
            background: 'white',
            boxShadow: '-2px 2px 2px 0 rgba(178,178,178,.4)',
            content: '',
            height: 15,
            left: 0,
            position: 'absolute',
            top: 0,
            transform: 'translate(-50%,-50%) rotate(-45deg)',
            width: 15
        },
        mapPhoto: {
            width: '100%',
            paddingTop: '70%',
            position: 'absolute',
            top: 0,
            left: 0,
            borderRadius: '5px 5px 0 0'
        },
        close: {
            position: 'absolute',
            right: 10,
            background: 'white',
            opacity: .6,
            borderRadius: '100%',
            padding: 5,
            cursor: 'pointer'
        },
        mapRight: {
            paddingTop: '69%',
            marginTop: 5,
            paddingRight: 12,
            paddingLeft: 12
        },
        title: {
            color: theme.palette.primary.main,
            fontWeight: 600,
            fontSize: 18,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        button: {
            border: `1px solid ${theme.palette.secondary.main}`,
            color: theme.palette.secondary.main,
            fontWeight: 600,
            fontSize: 14,
            padding: '6px 10px',
            borderRadius: '50px',
            margin: '8px 0',
            float: 'left',
            fontFamily: 'Avenir, Avenir Next, \'Montserrat\', \'Montserrat\'',
            cursor: 'pointer'
        },
        description: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
}))

export default function PopupWindow(props){

    const classes = useStyles()

    const { show, stories, close, viewStoryClicked, above, popupWindowWidth } = props

    const [singleStoryImage, setSingleStoryImage] = useState('')

    const Context  = useContext(MobileContext)
    const isMobile = Context.isMobile


    useEffect(() => {
        if(show && stories.length === 1){
            const story = stories[0]
            if(story.image){
                if(story.image.includes('.mp3')){
                    setSingleStoryImage(AudiotrackIcon)
                }else if(story.image.includes('.pdf')){
                    setSingleStoryImage(PDFIcon)
                }else{
                    setSingleStoryImage(story.image)
                }
            }
        }
    }, [show, stories])

    
    const getWidth = () => {
        if(popupWindowWidth) return popupWindowWidth
        if(isMobile) return 150
        return 300
    }

    return (
        <>
        {stories.length > 1 && <Carousel
            renderBottomCenterControls={null} 
            withoutControls={stories.length === 1} 
            className={classes.root} 
            style={{
                display: show ? 'block' : 'none', 
                transform: above ? 'translate(-50%,-100%)': 'translate(-100%,-50%)',
                width: getWidth(),
                height: '300px'}}>
                {stories.map(story => (
                    <CarouselItem
                        key={story._id}
                        classes={classes}
                        story={story}
                        close={close}
                        viewStoryClicked={viewStoryClicked} />
                ))}
        </Carousel>}
        {stories.length === 1 &&
            <div className={classes.root} style={{
                display: show ? 'block' : 'none', 
                transform: above ? 'translate(-50%,-100%)': 'translate(-100%,-50%)',
                width: getWidth() }}>
                <div 
                    className={classes.mapPhoto} 
                    style={{ background: `url(${singleStoryImage})center center/cover no-repeat`}} />
                <CloseButton className={classes.close} onClick={close}/>
                <div className={classes.mapRight}>
                    <div className={classes.title}>{stories[0].title}</div>
                    <div className={classes.button} onClick={() => viewStoryClicked(stories[0])}>View Story</div>
                    {/* TODO: [P3MVP-214] fix arrow positioning */}
                    {/* <div className={classes.arrow}/> */}
                </div>
            </div>
        }
        </>
    )
}