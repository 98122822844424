import React, { useEffect, useState } from 'react'
import CloseButton from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core'
import AudiotrackIcon from  './headphones-monochrome.png'
import PDFIcon from './pdf_icon.jpg'


const carouselItemStyles = makeStyles(theme => ({
    root: {
        boxSizing: 'border-box',
        overflow: 'hidden',
        top: 0,
        left: 0,
        transform: 'translate(-50%,-100%)',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 2px 7px 1px rgba(0,0,0,0.3)',
        zIndex: 1000,
        position: 'absolute !important',
        width: '300px !important', 
        height: '300px !important'
    },
    mapPhoto: {
        width: '100%',
        paddingTop: '70%',
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: '5px 5px 0 0',
        objectFit: 'cover'
    },
    close: {
        position: 'absolute',
        right: 10,
        background: 'white',
        opacity: .6,
        borderRadius: '100%',
        padding: 5,
        cursor: 'pointer'
    },
    mapRight: {
        paddingTop: '69%',
        marginTop: 5,
        paddingRight: 12,
        paddingLeft: 12
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: 18,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    button: {
        border: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.secondary.main,
        fontWeight: 600,
        fontSize: 14,
        padding: '6px 10px',
        borderRadius: '50px',
        margin: '8px 0',
        float: 'left',
        fontFamily: 'Avenir, Avenir Next, \'Montserrat\', \'Montserrat\'',
        cursor: 'pointer'
    },
    description: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}))



export default function CarouselItem(props){

    const { story, close, viewStoryClicked } = props

    const classes = carouselItemStyles()

    const [image, setImage] = useState('')


    useEffect(() => {

        if(!story.image) return

        window.dispatchEvent(new Event('resize'))

        if(story.image.includes('.mp3')){
            setImage(AudiotrackIcon)
        }else if(story.image.includes('.pdf')){
            setImage(PDFIcon)
        }else{
            setImage(story.image)
        }
    }, [story.image])


    return (
        <>
            <div className={classes.mapPhoto} style={{ background: `url(${image})center center/cover no-repeat`}}></div>
            {/* <img className={classes.mapPhoto} src={image} onLoad={handleImageLoaded} alt="" /> */}
            <CloseButton className={classes.close} onClick={close}/>
            <div className={classes.mapRight}>
                <div className={classes.title}>{story.title}</div>
                <div className={classes.button} onClick={() => viewStoryClicked(story)}>View Story</div>
                {/* TODO: [P3MVP-214] fix arrow positioning */}
                {/* <div className={classes.arrow}/> */}
            </div>
        </>    
    )
}