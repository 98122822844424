import React from 'react'
import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/core/styles'
import YouTubePlayer from 'react-player/lib/players/YouTube'
import Caption from './Caption'

const useStyles = makeStyles({
    card: {
      position: 'relative',
      width: '100%',
    }
  })

function YouTube(props){

    const classes = useStyles()
    const { asset, height } = props


    const handleVideoReady = () => {
      window.dispatchEvent(new Event('resize'))
    }


    return(
        <Card className={classes.card}>
            <YouTubePlayer 
              height={height} 
              width={'100%'} 
              url={asset.link} 
              controls
              onReady={handleVideoReady} />
            {asset.caption && <Caption asset={asset} />}
        </Card>
    )
}

export default YouTube