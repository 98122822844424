import React, { useEffect, useState } from 'react'
import Story from './Story'
import NoStoriesFound from '@material-ui/icons/PhotoLibrary'
import { makeStyles } from '@material-ui/styles'
import { isMobile } from 'react-device-detect'

const useStyles = makeStyles({
  noStoriesFound: {
    width: '5em',
    height: '5em',
  },
})

export default function StoriesList(props) {
  const classes = useStyles()

  const { stories, showStory, orgId, loading } = props

  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])

  useEffect(() => {
    if (!stories) return

    const left = stories ? stories.filter((_, index) => index % 2 === 0) : []
    const right = stories ? stories.filter((_, index) => index % 2 === 1) : []

    setLeft(left)
    setRight(right)
  }, [stories])

  if (loading === false && stories.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          margin: '0 0 65 0',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <NoStoriesFound className={classes.noStoriesFound} />
          <div style={{ fontSize: 'larger' }}>No Stories Found</div>
        </div>
      </div>
    )
  } else if (isMobile) {
    return (
      <div
        style={{
          display: 'flex',
          margin: '0 0 65 0',
          justifyContent: 'center'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {stories.map((story, index) => (
            <Story
              key={index}
              story={story}
              showStory={showStory}
              orgId={orgId}
            />
          ))}
        </div>
      </div>
    )
  } else {
    return (
      <div
        style={{
          display: 'flex',
          margin: '0 0 65 0',
          justifyContent: 'center'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          {left.map((story, index) => (
            <Story
              key={index}
              story={story}
              showStory={showStory}
              orgId={orgId}
            />
          ))}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          {right.map((story, index) => (
            <Story
              key={index}
              story={story}
              showStory={showStory}
              orgId={orgId}
            />
          ))}
        </div>
      </div>
    )
  }
}
