import React from 'react'
import { makeStyles } from '@material-ui/core'

const K_WIDTH = 40
const K_HEIGHT = 40


const markerWithNumberStyles = makeStyles(theme => ({
    root: {
        // initially any map object has left top corner at lat lng coordinates
        // it's on you to set object origin to 0,0 coordinates
        position: 'absolute',
        width: K_WIDTH,
        height: K_HEIGHT,
        left: -K_WIDTH / 2,
        top: -K_HEIGHT / 2,

        border: `5px solid ${theme.palette.secondary.main}`,
        borderRadius: K_HEIGHT,
        backgroundColor: 'white',
        textAlign: 'center',
        color: '#3f51b5',
        fontSize: 16,
        fontWeight: 'bold',
        padding: 4,
        cursor: 'pointer',
        boxShadow: '0 2px 7px 1px rgba(0,0,0,0.3)',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    }
}))


export default function MarkerWithNumber(props){

    const { click, numberOfStories } = props
    const classes = markerWithNumberStyles()

    return (
        <div className={classes.root} onClick={click}>
            {numberOfStories}
        </div>
    )

}