import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import SubCollection from './SubCollection'
import DefaultCollectionCoverPhoto from '../CollectionsPage/default_collection_cover.png'


const useStyles = makeStyles({
    image: {
        width: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: 5,
        paddingTop: '60%',
        marginBottom: 5,
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '0 0 6px rgba(0,0,0,.6)'
        }
    },
    title: {
        fontSize: 16, fontWeight: 600, letterSpacing: '-.5px', color: '#666'
    }
})

function CollectionButton(props){

    const classes = useStyles()
    const { app, collection, clickCollection, subCollectionClick } = props
    const [image, setImage] = useState('')

    useEffect(() => {
        if(collection.coverPhoto){
            setImage(collection.coverPhoto)
        }else{
            setImage(DefaultCollectionCoverPhoto)
        }
    }, [collection, app])

    
    return (
        <div onClick={() => clickCollection(collection._id)}>
            <div className={classes.image}
                style={{backgroundImage: `url(${image})`}} />
            <div className={classes.title}>
                {collection.title}
            </div>
            {collection.subCollections.map(subCollection  => (
                <SubCollection 
                    key={subCollection._id} 
                    subCollection={subCollection} 
                    click={(e, subCollection) => subCollectionClick(e, collection._id, subCollection)} />
            ))}
        </div>
    )
}

export default CollectionButton