import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AudiotrackIcon from '../headphones-monochrome.png'
import PDFIcon from '../pdf_icon.jpg'
import { isMobile } from 'react-device-detect'
import api from '../exhibitApi'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      borderRadius: '8px 8px 9px 9px',
      '&:hover': {
        boxShadow: '0 0 8px rgba(0,0,0,.3)',
        cursor: 'pointer',
      },
    },
    actionArea: {
      display: 'flex',
      lineHeight: 1.5,
      padding: '5px 4%',
      bottom: 0,
      width: '100%',
      boxSizing: 'border-box',
      float: 'left',
      fontWeight: 700,
      letterSpacing: '-.3px',
      background: theme.palette.primary.main,
      color: 'white',
      borderRadius: '0 0 9px 9px',
    },
    buttonArea: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      width: '20%',
      color: 'white',
      minWidth: 90,
      backgroundColor: theme.palette.secondary.main,
      textAlign: 'center',
      fontWeight: 600,
      float: 'right',
      padding: 5,
      borderRadius: 50,
      fontSize: 14,
      cursor: 'pointer',
    },
    date: {
      color: theme.palette.primary.main,
      position: 'absolute',
      right: 20,
      top: 20,
      borderRadius: 20,
      padding: '4px 10px',
      background: 'rgba(255,255,255,.7)',
      fontWeight: 600,
      fontFamily: 'Montserrat',
      fontSize: 14,
      letterSpacing: '-.7px',
    },
  }
})

export default function Story(props) {
  const { story, showStory } = props

  const [image, setImage] = useState('')

  const classes = useStyles()

  const date = story.formated_start_date

  useEffect(() => {
    if (!story.image) return

    if (story.image.includes('.mp3')) {
      setImage(AudiotrackIcon)
    } else if (story.image.includes('.pdf')) {
      setImage(PDFIcon)
    } else {
      let width, height

      if (isMobile) {
        width = 750
        height = 750

      } else {
        width = 1000
        height = 1000
      }

      const link = api.image().imageUrl(story.image, width, height)
      setImage(link)
    }

  }, [api, story.image])

  const handleStoryClick = () => {
    window.scrollTo(0, 0)
    showStory(story._id)
  }

  const handleImageLoaded = () => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 0)
  }

  const handleImageError = () => {
    setImage(story.image)
  }

  return (
    <div style={{ margin: '20px 10px' }}>
      <div
        className={classes.root}
        onClick={handleStoryClick}
        style={{ boxSizing: 'border-box', position: 'relative' }}
      >
        <img
          src={image}
          onLoad={handleImageLoaded}
          onError={handleImageError}
          alt={story.title}
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
            width: '100%',
            height: '100%',
            borderRadius: '8px 8px 0 0',
          }}
        />
        {date && <div className={classes.date}>{date}</div>}
        <div className={classes.actionArea}>
          <div style={{ flexGrow: 1, marginRight: 16 }}>{story.title}</div>
          <div className={classes.buttonArea}>
            <div className={classes.button} onClick={handleStoryClick}>
              View
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
