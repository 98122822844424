import React from 'react'
import HiddenImage from './error-404-monochrome-1200px.png'

export default function Hidden(){

    return (
        <div style={{
            height: '100%',
            backgroundImage: `url(${HiddenImage})`,    
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain'}}/>
    )
}