import React, { useState, useEffect, useContext, useRef } from 'react'
import Header from './Header'
import Main from './main/Main'
import Drawer from './sidepanel/Drawer'
import MobileContext from './context/MobileContext'
import {
  DRAWER_ITEM_TEXT,
  HEADER_ITEM_TEXT,
  FOOTER_HEIGHT,
  NO_OF_STORIES_TO_FETCH,
} from './Util'
import { useMediaQuery } from '@material-ui/core'
import { useDebounce } from 'use-debounce'

export default function SubPortland(props) {
  const { app, appId, collections, advancedSearchClicked } = props

  const Context = useContext(MobileContext)
  const isMobile = Context.isMobile

  const maxWidth1025px = useMediaQuery('(max-width: 1025px)')

  const mainRef = useRef()

  const [stories, setStories] = useState([])
  const [allStories] = useState([])
  const [collection, setCollection] = useState(undefined)
  const [subCollection, setSubCollection] = useState('')
  const [featuredStory, setFeaturedStory] = useState(undefined)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [skip, setSkip] = useState(0)
  const [debouncedSkip] = useDebounce(skip, 500)
  const [lastScrollTop, setLastScrollTop] = useState(0)

  useEffect(() => {
    if (isMobile) {
      setDrawerOpen(false)
    } else {
      setDrawerOpen(true)
    }
  }, [isMobile])

  useEffect(() => {
    if (app && featuredStory === undefined && stories.length !== 0) {
      const story = stories.find((s) => s._id === app.featuredStory)
      const copy = { ...story }
      setFeaturedStory(copy)
    }
  }, [app, stories])

  const hasCollection = (story, collectionId) => {
    return story.collections.includes(collectionId)
  }

  const handleClickCollection = (collectionId) => {
    const collection = collections.find((c) => c._id === collectionId)
    const collectionCopy = { ...collection }
    setCollection(collectionCopy)
    const arr = allStories.filter((story) => hasCollection(story, collectionId))
    setStories(arr)
    setSubCollection('')
    props.history.push(`${props.match.url}/collection/${collectionId}`)
  }

  const handleSubCollectionClick = (event, collectionId, subCollection) => {
    event.stopPropagation() // prevent collection click function above from being called since the
    // sub collection is nested within it
    const collection = collections.find((c) => c._id === collectionId)
    const collectionCopy = { ...collection }
    setCollection(collectionCopy)
    const arr = allStories.filter((story) => hasCollection(story, collectionId))
    setStories(arr)
    setSubCollection(subCollection)
    props.history.push(`${props.match.url}/collection/${collectionId}`)
    handleCloseDrawer()
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setDrawerOpen(open)
  }

  const handleCloseDrawer = () => {
    setDrawerOpen(false)
  }

  const handleSearch = (term) => {
    props.history.push(`${props.match.url}/search?value=${term}`)
  }

  const handleStoryPageTagClicked = (tag) => {
    props.history.push(`${props.match.url}/search?tag=${tag.value}`)
  }

  const handleResetSearch = () => {
    props.history.push(props.match.url)
  }

  const handleDrawerItemClick = (text) => {
    switch (text) {
      case DRAWER_ITEM_TEXT.EXPLORE:
        props.history.push(`/present/${app._id}/portland`)
        break
      case DRAWER_ITEM_TEXT.ART_MAP:
        props.history.push(`${props.match.url}/art-map`)
        break
      case DRAWER_ITEM_TEXT.WALKING_TOUR_BROCHURE:
        window.open(
          'https://racc.org/wp-content/uploads/2016/01/ArtWalk_2014.pdf',
          '__blank'
        )
        break
      case DRAWER_ITEM_TEXT.CONTACT_US:
        window.open('https://racc.org/about/contact-us/', '__blank')
        break
      case DRAWER_ITEM_TEXT.COLLECTIONS:
        props.history.push(`${props.match.url}/collections`)
        break
      case HEADER_ITEM_TEXT.ABOUT:
        window.open('https://racc.org/about/', '__blank')
        break
      case HEADER_ITEM_TEXT.OUR_PROGRAMS:
        window.open('https://racc.org/programs/', '__blank')
        break
      case HEADER_ITEM_TEXT.RESOURCES:
        window.open('https://racc.org/resources/', '__blank')
        break
      default:
        console.error('should not be here')
    }
    handleCloseDrawer()
  }

  // Scrolling horizontally on the featured stories triggers
  // a scroll that loads more stories
  // function checks if the page has scrolled vertically and not horizontally
  // https://stackoverflow.com/a/31223774/4455570
  const isVerticalScroll = (scrollTop) => {
    // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
    setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop) // For Mobile or negative scrolling
    if (scrollTop > lastScrollTop) {
      // downscroll code
      return true
    } else if (scrollTop === lastScrollTop) {
      console.log('no vertical scroll')
      // no scroll
      return false
    } else {
      // upscroll code
      return true
    }
  }

  // https://stackoverflow.com/a/49573628/4455570
  const handleScroll = (e) => {
    // strict equality doesn't always work; introducing delta of 1 for such cases.
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      ) <= 1
    if (bottom && isVerticalScroll(e.target.scrollTop)) {
      setSkip((prevSkip) =>
        Math.min(
          prevSkip + NO_OF_STORIES_TO_FETCH,
          debouncedSkip + NO_OF_STORIES_TO_FETCH
        )
      )
    }
  }

  const handleExitedStoryFeedCollectionPage = () => {
    setSkip(0) // reset skip once user exists StoryFeedCollectionPage
  }

  const handleResetSkip = (newSkip) => {
    setSkip(newSkip)
  }

  // TODO: [P3MVP-469] perform similar fix for david and lflb
  const handleMoveToTop = () => {
    if (mainRef.current) {
      mainRef.current.scrollTop = 0
    }
  }

  return (
    <>
      <Header
        app={app}
        toggleDrawer={toggleDrawer(!drawerOpen)}
        search={handleSearch}
        advancedSearchClicked={advancedSearchClicked}
        maxWidth1025px={maxWidth1025px}
      />

      <div
        ref={mainRef}
        onScroll={handleScroll}
        style={{
          position: 'absolute',
          top: 100,
          left: 0,
          right: 0,
          overflow: 'auto',
          bottom: FOOTER_HEIGHT,
        }}
      >
        <Main
          {...props}
          app={app}
          appId={appId}
          drawerOpen={drawerOpen}
          featuredStory={featuredStory}
          collection={collection}
          subCollection={subCollection}
          collections={collections}
          stories={stories}
          allStories={allStories}
          storyPageTagClick={handleStoryPageTagClicked}
          viewCollectionClicked={handleClickCollection}
          resetSearch={handleResetSearch}
          moveToTop={handleMoveToTop}
          skip={debouncedSkip}
          exitedStoryFeedCollectionPage={handleExitedStoryFeedCollectionPage}
          resetSkip={handleResetSkip}
        />
      </div>

      <Drawer
        {...props}
        app={app}
        open={drawerOpen}
        isMobile={isMobile}
        toggle={toggleDrawer}
        collections={collections}
        clickCollection={handleClickCollection}
        subCollectionClick={handleSubCollectionClick}
        drawerItemClick={handleDrawerItemClick}
        maxWidth1025px={maxWidth1025px}
      />
    </>
  )
}
