import React from 'react'
import { makeStyles } from '@material-ui/core'
import ExpandImage from './expand.png'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 40,
    height: 33,
    background: `url(${ExpandImage})center center/67% no-repeat rgba(0,0,0,.5)`,
    position: 'absolute',
    top: 10,
    right: 10,
    borderRadius: 3,
    zIndex: 1000, // for PDFs - canvas blocks the element without this
    [theme.breakpoints.down('xs')]: {
      height: 25,
      width: 30,
    },
  },
}))

export default function PicExpand(props) {
  const classes = useStyles()

  const { click } = props

  return <div className={classes.root} onClick={click} />
}
