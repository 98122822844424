export const HEADER_ITEM_TEXT = {
  ABOUT: 'About',
  OUR_PROGRAMS: 'Our Programs',
  RESOURCES: 'Resources',
  ADVANCED_SEARCH: 'Advanced Search',
}

export const DRAWER_ITEM_TEXT = {
  EXPLORE: 'Explore',
  ART_MAP: 'Art Map',
  WALKING_TOUR_BROCHURE: 'Walking Tour Brochure',
  CONTACT_US: 'Contact Us',
  COLLECTIONS: 'Collections:',
}

export const FOOTER_HEIGHT = 78

export const NO_OF_STORIES_TO_FETCH = 20

const locationNull = (location) => {
  return (
    location.lat === null ||
    location.lat === undefined ||
    location.lng === null ||
    location.lng === undefined
  )
}

const locationAlreadyExists = (arr, location) => {
  for (let index = 0; index < arr.length; index++) {
    const l = arr[index].location
    if (l.lat === location.lat && l.lng === location.lng) {
      return index
    }
  }
  return -1
}

export const getUniqueLocations = (stories) => {
  const arr = []

  for (let index = 0; index < stories.length; index++) {
    const story = stories[index]
    const location = story.location
    if (locationNull(location)) continue
    const locationIndex = locationAlreadyExists(arr, location)
    if (locationIndex === -1) {
      // location does not exist
      const storiesArray = []
      storiesArray.push(story)
      arr.push({
        location: { ...location },
        stories: storiesArray,
      })
    } else {
      arr[locationIndex].stories.push(story)
    }
  }

  return arr
}
