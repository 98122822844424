/* eslint-disable no-dupe-keys */
import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core'
import AudiotrackIcon from  '../headphones-monochrome.png'
import PDFIcon from '../pdf_icon.jpg'

const Story = ({ dateClass, actionAreaClass, buttonAreaClass, buttonClass, story, viewClicked, index, loadIndex, isMobile }) => {

    const [image, setImage] = useState('/placeholder.png')

    const [isLoadReady, setIsLoadReady] = useState(false)

    const date = story.formatted_start_date

    const getStyle = () => {

        const style = {}

        if(isMobile){
            style.width = '100%'
        }else{
            style.minWidth = 300
            style.maxWidth = 300
            style.margin = '0 16px'
        }

        style.boxSizing = 'border-box'
        style.position = 'relative'
        style.height = 200

        return style

    }

    //set image once we're close to scrolling into view
    //this prevents all images from loading
    useEffect(() => {
        if(!isLoadReady) return

        if(story.image){
            if(story.image.includes('.mp3')){
                setImage(AudiotrackIcon)
            }else if(story.image.includes('.pdf')){
                setImage(PDFIcon)
            }else{
                setImage(story.image)
            }
        }
    }, [isLoadReady, story.image])

    //once the image is loaded, skip recalculation
    useEffect(() => {
        if(isLoadReady) return
        if(index < loadIndex) {
            setIsLoadReady(true)
        }

    }, [index, loadIndex])

    return (
        <li style={getStyle()}>
            <img src={image} alt={story.title} style={{height: '300px', objectFit: 'cover', objectPosition: 'center', width: '100%', height: '100%', borderRadius: '8px 8px 0 0'}}/>
            {date && <div className={dateClass}>{date}</div>}
            <div className={actionAreaClass}>
                <div style={{flexGrow: 1, marginRight: 16, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {story.title}
                </div>
                <div className={buttonAreaClass}>
                    <div className={buttonClass} onClick={() => viewClicked(story)}>{isMobile ? 'View' : 'Go to'}</div>
                </div>
            </div>
        </li>
    )
}

const useStyles = makeStyles(theme =>({
    root: {
        display: 'flex',
        overflowX: 'hidden',
        overflowY: 'scroll',
        flexDirection: 'column',
    },
    date: {
        color: theme.palette.primary.main,
        position: 'absolute',
        right: 20,
        top: 20,
        borderRadius: 20,
        padding: '4px 10px',
        background: 'rgba(255,255,255,.7)',
        fontWeight: 600,
        fontFamily: 'Montserrat',
        fontSize: 14,
        letterSpacing: '-.7px'
    },
    actionArea: {
        position: 'absolute',
        display: 'flex',
        lineHeight: 1.5,
        padding: '5px 4%',
        bottom: 5,
        width: '100%',
        boxSizing: 'border-box',
        float: 'left',
        fontWeight: 700,
        letterSpacing: '-.3px',
        background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(0,0,0,1) 100%)',
        color: 'white',
        borderRadius: '0 0 9px 9px'
    },
    buttonArea: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        width: '20%',
        color: 'white',
        minWidth: 90,
        backgroundColor: theme.palette.secondary.main,
        textAlign: 'center',
        fontWeight: 600,
        float: 'right',
        padding: 5,
        borderRadius: 50,
        fontSize: 14,
        cursor: 'pointer'
    },
}))


export default function ResultsList(props){

    const classes = useStyles()

    const { stories, resultItemClicked, isMobile } = props

    const [loadIndex, setLoadIndex] = useState('')

    const getUlPositioning = () => {

        let style =  {}

        if(!isMobile){
            style = {
                position: 'absolute',
                top: 70,
                right: '2%',
                bottom: 60
            }
        }else{
            style.padding = 0
        }

        style.display = stories.length === 0 ? 'none' : 'flex'

        return style

    }

    useEffect(() => {
        document.getElementById('results-list').scrollTop = 0
        if(stories) {
            handleScroll()
        }
    }, [stories])

    const handleScroll = () => {
        const containerHeight = 200
        const storyTolerance = 100
        const scrollPosition = document.getElementById('results-list').scrollTop
        const loadIfIndexBefore = scrollPosition / containerHeight + storyTolerance
        setLoadIndex(loadIfIndexBefore)
    }

    return (
        <ul id='results-list' onScroll={handleScroll} className={classes.root} style={getUlPositioning()}>
            {stories.map((story, index) => (
                <Story
                    key={story._id}
                    story={story}
                    dateClass={classes.date}
                    actionAreaClass={classes.actionArea}
                    buttonAreaClass={classes.buttonArea}
                    buttonClass={classes.button}
                    viewClicked={resultItemClicked}
                    index={index}
                    loadIndex={loadIndex}
                    isMobile={isMobile} />
            ))}
        </ul>
    )
}
