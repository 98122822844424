
// TODO : zoom in on first story

import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import MarkerWrapper from './MarkerWrapper'

import './Map.css'

const defaultCenter = {
    lat: 38.267153,
    lng: -97.7430608
}


function getMapCenter(props){
  if(props.app.mapCenterAddressCoords){
    return props.app.mapCenterAddressCoords
  }if(props.center){
    return props.center
  }else{
    return defaultCenter
  }
}

export class GoogleMaps extends Component {


  constructor(props){
    super(props)

    this.state = {
      center: getMapCenter(props),
      zoom: props.app.mapZoom,
      popupWindowStory: undefined,
      indexOfPopupWindowToShow: undefined
    }

  }

  


  handleChangeCenter = (lat, lng) => {
    this.setState({
      center: {
        lat: lat,
        lng: lng
      }
    })
  }

  handleMarkerClick = (selectedIndex, lat, lng) => {
    const s = this.getStoriesForLocation(lat, lng)
    this.setState({
      curSelectedIndex: selectedIndex,
      popupWindowStory: s,
      center: {
        lat: lat,
        lng: lng
      }
    })
  }

  getStoriesForLocation = (lat, lng) => {
    return this.props.stories.find(s => s.location.lat === lat && s.location.lng === lng)
  }

  handleClosePopupWindow = () => {
    this.setState({
      indexOfPopupWindowToShow: undefined
    })
  }

  handleShowPopupWindow = (index) => {
    this.setState({
      indexOfPopupWindowToShow: index
    })

  }
  

  render() {

    const { app, data, viewStoryClicked, mapStyle, showExploreButton, exploreClicked }= this.props
    

    return (
        <div className={'googleMapsRoot'} style={{position: 'relative'}}>
            <GoogleMapReact
                onChange={this.props.onDragend}
                center={this.state.center}
                zoom={this.state.zoom}
                options={{ styles: mapStyle, scrollwheel: false }}>
                {data.map((datum, index) => (
                  <MarkerWrapper 
                    key={index}
                    index={index}
                    indexOfPopupWindowToShow={this.state.indexOfPopupWindowToShow}
                    app={app} 
                    datum={datum}
                    lat={datum.location.lat} 
                    lng={datum.location.lng} 
                    viewStoryClicked={viewStoryClicked}
                    markerClick={() => this.handleMarkerClick(index, datum.lat, datum.lng)}
                    closePopupWindow={this.handleClosePopupWindow}
                    popupStory={this.state.popupWindowStory}
                    changeCenter={this.handleChangeCenter}
                    showPopupWindow={() => this.handleShowPopupWindow(index)}
                    popupWindowHeight={180} />
                    
                ))}
            </GoogleMapReact>
            <div style={{
              position: 'absolute', 
              display: showExploreButton ?  'flex' : 'none',
              alignItems: 'center',
              justifyContent: 'center',
              height: 50,
              width: 130,
              bottom: '15%', 
              backgroundColor: '#FF5368',
              color: 'white',
              borderRadius: 25,
              fontWeight: 700,
              cursor: 'pointer',
              left: '5%'}} onClick={exploreClicked}>Explore</div>
        </div>
    )
  }
}

GoogleMaps.defaultProps = {
  center: {
    lat: 38.267153,
    lng: -97.7430608
  },
  zoom: 4
}

export default GoogleMaps