// TODO : footer overlaps popup when popup is open
import React from 'react'
import { makeStyles } from '@material-ui/core'
import HomeIcon from './home_icon.png'
import CollectionIcon from './collection_icon.png'
import MapIcon from './map_icon.png'
import GoBackIcon from './assets/go-back.png'
import AccessiblilityIcon from './accessibility_icon.png'
import { FOOTER_HEIGHT } from './Util'
import clsx from 'clsx'

const FooterItem = ({
  className,
  imgClass,
  altText,
  click,
  Icon,
  text,
  onLoad,
}) => (
  <div onLoad={onLoad} className={className}>
    <img
      className={imgClass}
      alt={altText}
      onClick={click}
      src={Icon}
      // If element is for accessibility, create empty space that will be filled by Accessibe's button
      style={{
        visibility: text === 'Accessibility' ? 'hidden' : 'visible',
      }}
    />
    <div>{text}</div>
  </div>
)

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      height: FOOTER_HEIGHT,
      background: theme.palette.secondary.main,
      zIndex: 900, // light box has a zIndex of 1200 so this value should be anything less than that
    },
    footerText: {
      fontSize: 16,
      fontWeight: 600,
      color: 'white',
      [theme.breakpoints.down(321)]: {
        // iPhone 5 width is 320px
        fontSize: 10,
      },
    },
    imgIcon: {
      width: 24,
      height: 24,
      [theme.breakpoints.down(321)]: {
        // iPhone 5 width is 320px
        height: 20,
        width: 20,
      },
    },
    homeIcon: {
      float: 'left',
      margin: '0 5px',
      height: 50,
      paddingTop: 16,
      lineHeight: '32px',
      width: 50,
      textAlign: 'center',
    },
    backIcon: {
      float: 'left',
      margin: '0 5px',
      height: 50,
      paddingTop: 16,
      lineHeight: '32px',
      width: 50,
      textAlign: 'center'
    },
    mapIcon: {
      float: 'right',
      height: 50,
      paddingTop: 16,
      lineHeight: '32px',
      width: 50,
      textAlign: 'center',
      margin: '0 auto',
    },
    collectionsIcon: {
      float: 'right',
      height: 50,
      paddingTop: 16,
      lineHeight: '32px',
      width: 115,
      textAlign: 'center',
      margin: '0 auto',
    },
    accessiblilityIcon: {
      height: 50,
      paddingTop: 16,
      lineHeight: '32px',
      width: 115,
      textAlign: 'center',
      marginRight: 5,
    },
  }
})

export default function Footer(props) {
  const { app } = props

  const classes = useStyles()

  const goHome = () => {
    props.history.push(`/present/${app._id}/portland`)
  }

  const goBack = () => {
    props.history.goBack()
  }

  const goToMap = () => {
    props.history.push(`${props.match.url}/art-map`)
  }

  const goToCollections = () => {
    props.history.push(`${props.match.url}/collections`)
  }

  // Necessary because the changes advised on this page: https://accessibe.com/support/customization/create-your-own-accessibility-button
  // do not work
  const handleAccessibilityLoaded = () => {
    const elements = document.getElementsByClassName('acsb-trigger') // get the element for Accessibe
    if (elements.length === 1) {
      const element = elements[0]
      const name = 'accessiblilityIcon' // add this class that can be found in index.css
      const arr = element.className.split(' ')
      if (arr.indexOf(name) == -1) {
        element.className += ' ' + name
      }
    }
  }

  return (
    <div className={classes.root}>
      <FooterItem
        className={clsx(classes.homeIcon, classes.footerText)}
        altText={'Go Home'}
        click={goHome}
        Icon={HomeIcon}
        text={'Home'}
        imgClass={classes.imgIcon}
      />
      <FooterItem
        className={clsx(classes.backIcon, classes.footerText)}
        altText={'Go Back'}
        text={'Back'}
        imgClass={classes.imgIcon}
        click={goBack}
        Icon={GoBackIcon}
      />
      <div style={{ flexGrow: 1 }} />
      <div style={{ display: 'flex' }}>
        <FooterItem
          className={clsx(classes.collectionsIcon, classes.footerText)}
          altText={'Collections'}
          text={'Collections'}
          click={goToCollections}
          Icon={CollectionIcon}
          imgClass={classes.imgIcon}
        />
        <FooterItem
          className={clsx(classes.mapIcon, classes.footerText)}
          altText={'Map'}
          text={'Map'}
          click={goToMap}
          Icon={MapIcon}
          imgClass={classes.imgIcon}
        />
        <FooterItem
          className={clsx(
            classes.accessiblilityIcon,
            classes.footerText,
            classes.imgIcon
          )}
          altText={'Accessibility'}
          text={'Accessibility'}
          onLoad={handleAccessibilityLoaded}
          Icon={AccessiblilityIcon}
          imgClass={classes.imgIcon}
        />
      </div>
    </div>
  )
}
