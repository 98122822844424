import React from 'react'
import { makeStyles } from '@material-ui/styles'
import ArrowBack from './white-arrow-left.png'
import ArrowForward from './white-arrow.png'

const useStyles = makeStyles(theme => {
    return {
        root: {
            alignItems: 'center',
            display: 'flex'
        },
        arrow: {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: 40, 
            width: 40
        }
    }
})

export default function Arrow(props){

    const { type, click, visible } = props

    const classes = useStyles()
    
    if(type === 'prev'){
        return (
            <div className={classes.root}  
                style={{display: visible ? 'flex' : 'none'}} 
                onClick={() => click('prev')}>
                <div className={classes.arrow} 
                style={{ backgroundImage: `url(${ArrowBack})`}}/>
            </div>
          )
    }

    return (
        <div className={classes.root} 
            style={{display: visible ? 'flex' : 'none', marginLeft: 'auto'}} // marginLeft in order to push arrow to the complete right end
            onClick={() => click('next')}>
             <div className={classes.arrow} 
                style={{ backgroundImage: `url(${ArrowForward})`}}/>
        </div>
      )
}