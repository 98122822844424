import React, { useState } from 'react'
import { Card, CardContent, makeStyles, Typography, CardActions, Button, TextField, FormControl, InputLabel, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
      minWidth: 350,
      maxWidth: 400,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    formControl: {
      margin: theme.spacing(3),
    },
  }))


export default function SearchCard(props){


    const { collections, disciplines, selectedCollection, selectedDiscipline, changeCollection, 
            changeDiscipline, search, reset, isMobile } = props

    const classes = useStyles()

    const [title, setTitle] = useState('')
    const [artist, setArtist] = useState('')
    const [keywords, setKeywords] = useState('')
    const [sortBy, setSortBy] = useState('')



    const handleChange = (e) => {

        const id = e.target.id
        const value = e.target.value

        switch(id){
            case 'title':
                setTitle(value)
                break
            case 'artist':
              setArtist(value)
              break
            case 'keywords':
              setKeywords(value)
              break
            default:
                console.error('[SearchCard:handleChange]', 'should not be here')
        }
    }

    const handleChangeRadioButton = (e) => {
        const value = e.target.value
        setSortBy(value)
    }

    const handleSearch = () => {
        if(title || artist || keywords || selectedCollection || selectedDiscipline) {
          search(title, artist, keywords, selectedCollection, selectedDiscipline, sortBy)
        }
        else {
          handleReset()
        }
    }

    const handleReset = () => {
      setTitle('')
      setArtist('')
      setKeywords('')
      setSortBy('')
      reset()
    }

    const getPosition = () => {
      if(isMobile){
        return {
          top: 0,
          left: 0
        }
      }

      return {
        top: '5%',
        left: '1%',
        position: 'absolute'
      }

    }


    return (
        <Card className={classes.root} style={getPosition()}>
        <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
                Search
            </Typography>
            <TextField fullWidth id="title" label="Title" value={title} onChange={handleChange} />
            <TextField fullWidth id="artist" label="Artist"  value={artist} onChange={handleChange} />
            <TextField fullWidth id="keywords" label="Keywords" value={keywords} onChange={handleChange} />
            <FormControl fullWidth>
              <InputLabel id="collection">Select Collection</InputLabel>
              <Select
                labelId="collection"
                id="collection"
                value={selectedCollection}
                onChange={changeCollection}>
                {collections.map(collection => (
                  <MenuItem key={collection._id} value={collection.title}>{collection.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="collection">Select Discipline</InputLabel>
              <Select
                labelId="discipline"
                id="discipline"
                value={selectedDiscipline}
                onChange={changeDiscipline}>
                {disciplines.map(discipline => (
                  <MenuItem key={discipline} value={discipline}>{discipline}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} component="fieldset" fullWidth>
              <FormLabel component="legend">Sort results by:</FormLabel>
              <RadioGroup aria-label="sort" name="sort" value={sortBy} onChange={handleChangeRadioButton}>
                <FormControlLabel value="title" control={<Radio />} label="Title" />
                <FormControlLabel value="artist-last-name" control={<Radio />} label="Artist's last name" />
                <FormControlLabel value="date-of-work" control={<Radio />} label="Date of work" />
              </RadioGroup>
            </FormControl>
        </CardContent>
        <CardActions>
            <Button color="primary" size="small" variant={'contained'} onClick={handleSearch}>Search</Button>
            <Button color="primary" size="small" variant={'contained'} onClick={handleReset}>Reset</Button>
        </CardActions>
    </Card>
    )
}