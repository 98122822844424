import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { SnackbarContent } from '@material-ui/core'


const styles = theme => ({
  close: {
    padding: theme.spacing(1/2),
  },
  content: {
    flexWrap: 'unset'
  }
})

class UtilSnackbar extends React.Component {


  render() {
    const { classes, open, close, message } = this.props
    return (
      <Snackbar
        data-testid="snackbar"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={5000}
        onClose={close}>
          <SnackbarContent
            aria-describedby='message-id'
            className={classes.content}
            message={<span id="message-id">{message}</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  className={classes.close}
                  onClick={close}>
                  <CloseIcon />
                </IconButton>
              ]} />
        </Snackbar>
    )
  }
}

Snackbar.defaultProps = {
  open: false,
  message: '',
  close: () => {}
}

export default withStyles(styles)(UtilSnackbar)
