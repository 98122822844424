import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { Document, Page, pdfjs } from 'react-pdf'
import CloseButton from './close.png'

import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`


const useStyles = makeStyles({
  root: {
    position: 'relative',
    height: 500
  },
  close: {
    width: 40,
    height: 40,
    background: `url(${CloseButton})center center/70% no-repeat rgba(0,0,0,.5)`,
    borderRadius: '100%',
    cursor: 'pointer',
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1000 // pdf canvas blocks the close button
  },
  pdfCanvas: {
    '& .react-pdf__Page__canvas': {
      height: 'unset !important',
      width: '80% !important',
      margin: '0 auto'
    }
  }
})


function PDFPreviewDialog(props) {

  const { open, close, PDFLink } = props

  const [numPages, setNumPages] = useState(null)

  const classes = useStyles()

  const handlePDFSuccess = (document) => {
    const { numPages } = document
    setNumPages(numPages)
  }
  const handlePDFError = (e) => {
    console.log('error loading pdf')
    console.error(e)
  }


  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      maxWidth={'md'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <div>
        <div style={{ flexGrow: 1 }} />
        <div className={classes.close} onClick={close} />
      </div>
      <DialogContent className={classes.root}>
        <DialogContentText id="alert-dialog-description">
          <Document
            file={PDFLink}
            onLoadSuccess={handlePDFSuccess}
            onLoadError={handlePDFError}>
            {Array.from(
              new Array(numPages),
              (_, index) => (
                <Page
                  className={classes.pdfCanvas}
                  object-fit="fill"
                  key={`page_${index + 1}`}
                  pageNumber={index + 1} />
              ),
            )}
          </Document>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

PDFPreviewDialog.defaultProps = {
  open: false,
  asset: {}
}

export default PDFPreviewDialog