import React from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import Portland from './Portland'

function App() {

  const appId = process.env.REACT_APP_ID

  return (
    <Router>
      <Route exact path="/">
        <Redirect to="/present/5e54043bbd14ee137bc461f7/portland" />
      </Route>

      <Route path="/present/:appId/portland" render={(props) =>
        <Portland
          {...props}
          appId={appId} />} />
    </Router>
  )
}

export default App
