import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    collectionItem: {
        margin: '13px 0',
        padding: '4px 0',
        fontWeight: 600,
        fontSize: 20,
        cursor: 'pointer',
        color: '#4A4A4A'
    }
})


export default function SidePanelItem(props){

    const { text, Icon, iconHeight, click } = props

    const classes = useStyles()

    return (
        <div
        onClick={() => click(text)} 
        className={classes.collectionItem} 
        style={{paddingLeft: 30, background: `url(${Icon})left center/${iconHeight}px no-repeat`}}>{text}</div>
    )
}