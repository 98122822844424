import React from 'react'
import Collection from './Collection'
import { FOOTER_HEIGHT } from '../Util'

export default function Collections(props) {
  const { app, collections, storyClicked } = props

  const isLastCollection = (collection) => {
    return collections[collections.length - 1] === collection
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: FOOTER_HEIGHT,
        overflow: 'auto',
      }}
    >
      {collections.map(collection => (
        <Collection
          key={collection._id}
          app={app}
          collection={collection}
          storyClicked={storyClicked}
          isLastCollection={isLastCollection(collection)}
        />
      ))}
    </div>
  )
}
