import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import GoogleMaps from './GoogleMaps'
import { allAppStoriesForMap, loadStoriesForApp  } from '../store/storiesSlice'
import api from '../exhibitApi'

function Map(props) {
  const { app, viewStoryClicked } = props
    const [mapStyle, setMapStyle] = useState([])
    const mapStoryData = useSelector(allAppStoriesForMap)
    const dispatch = useDispatch()

    useEffect(() => { window.scrollTo(0, 0) }, [])

    useEffect(() => {
      async function fetchMapStyle(name){
        const style = await api.mapStyle().getByName(name)
        setMapStyle(style)
      }

      if(app) {
        fetchMapStyle(app.mapStyle)
      }
    }, [app, api])

    useEffect(() => {
      dispatch(loadStoriesForApp(app._id))
    }, [app])

    return (
        <>
        <GoogleMaps
            app={app}
            data={mapStoryData}
            onDragend={() => {}}
            viewStoryClicked={viewStoryClicked}
            mapStyle={mapStyle} />
        </>
    )
}

export default Map
