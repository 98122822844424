import React from 'react'
import { makeStyles } from '@material-ui/core'
import Filter from './Filter'

const useStyles = makeStyles(() => {
    return {
        filters: {
            display: 'flex',
            boxSizing: 'border-box',
            padding: '5px 2%'
        }
    }
})



export default function Filters(props){

    const classes = useStyles()

    const { click, filters, selectedFilter } = props

    return (
        <div className={classes.filters}>
            {filters.map(filter => (
                <Filter key={filter} filterName={filter} selectedFilter={selectedFilter} click={() => click(filter)} />
            ))}    
        </div>
    )
}