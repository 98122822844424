import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import Type from './Type'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Tooltip from '@material-ui/core/Tooltip'


const useStyles = makeStyles(() => ({
    footer: {
        padding: '10px 18px 26px 18px',
        fontSize: '105.01%',
        boxSizing: 'border-box',
        color: '#999'
    },
    root: {
        margin: '0 0 7.5px',
        fontSize: '105.01%',
        boxSizing: 'border-box'
    },
    glyphicon: {
        fontSize: '95%',
        paddingLeft: 5,
        paddingTop: 5,
        position: 'relative',
        top: 1,
        display: 'inline-block',
        fontFamily: 'Glyphicons Halflings',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: 1
    },
    formGroup: {
        paddingTop: 5,
        marginBottom: 15,
        fontSize: '105.01%',
        boxSizing: 'border-box'
    }
}))

export default function PortlandMetaData(props) {

    const { metaData } = props

    const classes = useStyles()

    const [show, setShow] = useState(true)
    const [hasContent, setHasContent] =  useState(false)

    useEffect(() => {
        if(!isEmpty()){
            setHasContent(true)
        }
        // eslint-disable-next-line
    }, []);

    const isEmpty = () => {
        for (const key in metaData) {
            // eslint-disable-next-line no-prototype-builtins
            if (metaData.hasOwnProperty(key)) {
                const m = metaData[key]
                if(key !== '_id' && key !== '__v' && m !== ''){
                    return false
                }
            }
        }
        return true
    }

    const handleShowMetaData = () => {
        setShow(prevShow => !prevShow)
    }

    return (
        <div className={classes.footer} style={{display: hasContent ? 'block' : 'none'}}>
        <p className={classes.root}>
        <span style={{ display: 'flex', textAlign: 'center' }}>
            Additional Info
            {show && <span style={{ marginLeft: 8 }} onClick={handleShowMetaData}>
                <Tooltip title="Hide meta data" arrow placement="right-end">
                    <ExpandLessIcon />
                </Tooltip>
            </span>}
            {!show && <span style={{ marginLeft: 8 }} onClick={handleShowMetaData}>
                <Tooltip title="Show meta data" arrow placement="right-end">
                    <ExpandMoreIcon />
                </Tooltip>
            </span>}
        </span>
        </p>
        <div style={{display: show ? 'block' : 'none', animation: 'fadein 1s' }}>
            <div className={classes.formGroup}>
                <Type type={'Racc #'} value={metaData.raccNo}/>
            </div>
            <div className={classes.formGroup}>
                <Type type={'Artist'} value={metaData.artist}/>
            </div>
            <div className={classes.formGroup}>
                <Type type={'Medium'} value={metaData.medium}/>
            </div>
            <div className={classes.formGroup}>
                <Type type={'Dimensions'} value={metaData.dimensions}/>
            </div>
            <div className={classes.formGroup}>
                <Type type={'Discipline'} value={metaData.discipline}/>
            </div>
            <div className={classes.formGroup}>
                <Type type={'Conceputual Information'} value={metaData.conceptualInfo}/>
            </div>
            <div className={classes.formGroup}>
                <Type type={'Display Location'} value={metaData.displayLocation}/>
            </div>
            <div className={classes.formGroup}>
                <Type type={'Building Name'} value={metaData.buildingName}/>
            </div>
            <div className={classes.formGroup}>
                <Type type={'Bureau'} value={metaData.bureau}/>
            </div>
            <div className={classes.formGroup}>
                <Type type={'Room'} value={metaData.room}/>
            </div>
            <div className={classes.formGroup}>
                <Type type={'Street'} value={metaData.street}/>
            </div>
            <div className={classes.formGroup}>
                <Type type={'City'} value={metaData.city}/>
            </div>
            <div className={classes.formGroup}>
                <Type type={'State'} value={metaData.state}/>
            </div>
            <div className={classes.formGroup}>
                <Type type={'Zip'} value={metaData.zip}/>
            </div>
        </div>
    </div>
    )
}
