import React, { useState } from 'react'
import PopupWindow from './PopupWindow'
import MapsPin from './maps_pin.png'
import MarkerWithNumber from './MarkerWithNumber'



const Marker = (props) => {

    const { click } = props

    return (
        <img onClick={click} alt="Map Pin" src={MapsPin} 
            style={{height: 50, position: 'relative', cursor: 'pointer'}}/>
    )
}

export default function MarkerWrapper(props){

    const { datum, 
            index, 
            viewStoryClicked, 
            changeCenter, 
            showPopupWindow, 
            closePopupWindow, 
            indexOfPopupWindowToShow,
            placePopupWindowAbove,
            popupWindowWidth,
            popupWindowHeight } = props

    const [popupStories, setPopupStories] = useState([])

    const handleMarkerClick = () => {
        setPopupStories(datum.stories)
        const { lat, lng } = datum.location
        changeCenter(lat, lng)
        showPopupWindow()
    }

    
    return (
        <>
        {datum.stories.length > 1 && 
            <MarkerWithNumber 
                click={handleMarkerClick} 
                numberOfStories={datum.stories.length}/>}
        {datum.stories.length === 1 && <Marker click={handleMarkerClick}/>}
        <PopupWindow 
            show={indexOfPopupWindowToShow === index} 
            close={closePopupWindow} 
            stories={popupStories} 
            viewStoryClicked={viewStoryClicked}
            above={placePopupWindowAbove}
            popupWindowWidth={popupWindowWidth}
            popupWindowHeight={popupWindowHeight}/>
        </>
    )
}