import React from 'react'
import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles({
    root: {
        fontSize: 12,
        margin: '7px 0 7px 13%',
        fontWeight: 600,
        textTransform: 'uppercase',
        opacity: .7,
        boxSizing: 'border-box',
        '&:hover':{
            cursor: 'pointer',
            textDecoration: 'underline'
        }
    }
})

export default function Collection(props){

    const classes = useStyles()

    const { subCollection, click } = props

    return (
        <div className={classes.root} onClick={(e) => click(e, subCollection)}>
            {subCollection.title}
        </div>
    )
}