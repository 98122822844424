import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import DefaultCollectionCoverPhoto from '../CollectionsPage/default_collection_cover.png'
import { isMobile } from 'react-device-detect'
import api from '../exhibitApi'

import './FeaturedCollection.css'

const useStyles = makeStyles((theme) => {
  return {
    button: {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      padding: '2px 5%',
      float: 'right',
      fontSize: 14,
      fontWeight: 600,
      borderRadius: 50,
      cursor: 'pointer',
      minWidth: 35,
    },
  }
})

export default function FeaturedCollection(props) {
  const { featuredCollection, viewClicked } = props

  const classes = useStyles()

  const [image, setImage] = useState('')

  useEffect(() => {
    if (featuredCollection.coverPhoto) {
      setImage(
        isMobile
          ? api.image().imageUrl(featuredCollection.coverPhoto, 750, 750)
          : api.image().imageUrl(featuredCollection.coverPhoto, 1000, 1000)
      )
    } else {
      setImage(DefaultCollectionCoverPhoto)
    }
  }, [featuredCollection.coverPhoto])

  const clicked = () => {
    viewClicked(featuredCollection._id)
  }

  return (
    <div
      style={{ minWidth: 250, height: '100%', cursor: 'pointer' }}
      onClick={clicked}
    >
      <div
        className={'featuredCollectionItemImage'}
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <div className={'featuredCollectionItemText'}>
        <div className={'featuredCollectionItemTextTitle'}>
          {featuredCollection.title}
        </div>
        <span
          className={classes.button}
          onClick={() => viewClicked(featuredCollection._id)}
        >
          View
        </span>
      </div>
    </div>
  )
}
