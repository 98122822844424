import React, { useRef, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Slider from 'react-slick'
import Dotdotdot from 'react-dotdotdot'
import { isMobile } from 'react-device-detect'
import api from '../exhibitApi'

import './FeaturedStories.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(3),
    },
    featuredImageBackground: {
      textAlign: 'center',
      position: 'relative',
      borderRadius: 40,
      height: 300,
      [theme.breakpoints.up('md')]: {
        height: 400,
      },
    },
    featuredImage: {
      objectFit: 'cover',
      objectPosition: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: 40,
      zIndex: -1,
    },
    featured: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    featuredButton: {
      position: 'absolute',
      textAlign: 'center',
      padding: '4px 20px',
      fontSize: 16,
      lineHeight: '16px',
      fontWeight: 700,
      border: 'none',
      background: '#fff',
      color: '#fff',
      boxShadow: '0 5px 15px rgba(0,0,0,.4)',
      borderRadius: 50,
      backgroundColor: theme.palette.primary.main,
      width: 'fit-content',
      cursor: 'pointer',
      bottom: '8%',
      left: '2%',
    },
  }
})

export default function FeaturedStories(props) {
  const classes = useStyles()
  const theme = useTheme()

  const { featuredStories, showStory } = props

  const [slideIndex, setSlideIndex] = useState(0)

  const sliderRef = useRef(null)

  const handleAfterChange = (current) => {
    // Bug fix: handler is called during initial loading and sometimes the current value is -1
    if (current === -1) {
      setSlideIndex(0)
    } else {
      setSlideIndex(current)
    }
  }

  const storyClicked = () => {
    showStory(featuredStories[slideIndex]._id)
  }

  const settings = {
    arrows: true,
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    cssEase: 'linear',
    afterChange: (current) => handleAfterChange(current),
  }

  return (
    <div className={classes.root}>
      <Slider ref={sliderRef} {...settings}>
        {featuredStories.map((story, index) => {
          return (
            <div
              key={index}
              style={{
                position: 'relative',
              }}
              onClick={storyClicked}
            >
              <div
                className={classes.featuredImageBackground}
                style={{
                  backgroundImage: 'linear-gradient(to right top, rgba(0, 0, 0, 0.54), rgba(255, 255, 255, .4))',
                }}
              />
              <img
                src={
                  isMobile
                    ? api.image().imageUrl(story.image, 750, 750)
                    : api.image().imageUrl(story.image, 1000, 1000)
                }
                className={classes.featuredImage}
                width={'100%'}
                height={'100%'}
                alt={story.title}
              />
              {/* modifying zIndex due to bug with react-slick where the text from previous slides overlaps with text of current slide */}
              <div className={classes.featured}>
                <div className={'featuredArtWorkText'}>Featured Art Work</div>
                <div
                  className={'featuredText'}
                  style={{ display: slideIndex === index ? 'block' : 'none' }}
                >
                  <Dotdotdot clamp={3}>{story ? story.title : ''}</Dotdotdot>
                </div>
                <div
                  className={'featuredButton'}
                  style={{
                    display: story ? 'block' : 'none',
                    backgroundColor: theme.palette.primary.main,
                  }}
                  onClick={storyClicked}
                >
                  View
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}
