import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import Stories from './Stories'
import SubCollections from './SubCollections'
import Filters from './Filters'
import DefaultCollectionCoverPhoto from '../CollectionsPage/default_collection_cover.png'
import { NO_OF_STORIES_TO_FETCH } from '../Util'
import ProgressDialog from '../CollectionsPage/ProgressDialog'
import { useParams } from 'react-router-dom'
import api from '../exhibitApi'
import { selectedExhibit } from '../store/exhibitSlice'
import { useSelector } from 'react-redux'
import { getDate } from '@Pass-It-Down/exhibition-client/src/date'
import { ExhibitClient } from '@Pass-It-Down/exhibition-client/src/exhibitClient'

import './ScrollMenu.css'

const useStyles = makeStyles((theme) => {
  return {
    collectionImage: {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      left: 0,
      right: 0,
      height: 400,
      marginTop: 20,
    },
    collectionTitle: {
      marginLeft: '1%',
      position: 'absolute',
      zIndex: 1,
      fontSize: 32,
      textShadow: '0 2px 4px rgba(0,0,0,.4)',
      bottom: 32,
      fontWeight: 700,
      letterSpacing: '-.8px',
      color: 'white',
    },
    collectionDesc: {
      marginLeft: '1%',
      position: 'absolute',
      zIndex: 1,
      textShadow: '0 1px 2px #222',
      bottom: 10,
      whiteSpace: 'nowrap',
      width: '98%',
      overflow: 'hidden',
      color: 'white',
    },
    gradient: {
      paddingTop: '10%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      opacity: 0.8,
      zIndex: 0,
      background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(0,0,0,1) 100%)',
    },
    scrollMenuRoot: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      bottom: -80,
      right: 0,
      left: 0,
    },
    subCollections: {
      backgroundColor: theme.palette.secondary.main,
    },
    wrapperClass: {
      backgroundColor: theme.palette.primary.main,
    },
    active: {
      backgroundColor: theme.palette.primary.main,
      padding: '5px 20px',
      fontWeight: 600,
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      display: 'inline-block',
      borderRadius: 50,
      boxSizing: 'border-box',
    },
  }
})

const defaultList = [{ title: 'All' }]

const filters = ['Recently Added', 'Chronological']

function removeDuplicates(stories) {
  const final = []
  const ids = []

  for (let index = 0; index < stories.length; index++) {
    const story = stories[index]
    const id = story._id.toString()
    if (ids.includes(id) === false) {
      final.push(story)
      ids.push(id)
    }
  }
  return final
}

export default function StoryFeedCollection(props) {
  const classes = useStyles()

  const {
    showStory,
    subCollection,
    skip,
    resetSkip,
  } = props

  const { collectionId } = useParams()
  const exhibit = useSelector(selectedExhibit)
  const sortOrderLabel = ExhibitClient.getSortOrderLabel(exhibit?.storiesOrder)

  const [selected, setSelected] = useState('')
  const [list, setList] = useState(defaultList)
  const [selectedStories, setSelectedStories] = useState([])
  const [image, setImage] = useState('')
  const [allStories, setAllStories] = useState([])
  const [selectedFilter, setSelectedFilter] = useState('Recently Added')
  const [showProgressDialog, setShowProgressDialog] = useState(false)
  const [collection, setCollection] = useState(undefined)

  useEffect(() => {
    return () => {
      setSelectedStories([])
      setAllStories([])
      setShowProgressDialog(false)
    }
  }, [])

  useEffect(() => {
    if (collection && collection.coverPhoto) {
      setImage(collection.coverPhoto)
    } else {
      setImage(DefaultCollectionCoverPhoto)
    }
  }, [collection])

  useEffect(() => {
    if (skip > selectedStories.length) {
      resetSkip(selectedStories.length)
      return
    }
    setShowProgressDialog(true)
    const arr = []
    for (
      let index = skip;
      index < Math.min(allStories.length, skip + NO_OF_STORIES_TO_FETCH);
      index++
    ) {
      const story = allStories[index]
      arr.push(story)
    }
    setSelectedStories([...selectedStories, ...arr])
    setShowProgressDialog(false)
  }, [skip])

  function sortStories(stories) {
    stories = removeDuplicates(stories)

    switch (selectedFilter) {
      case 'Recently Added':
        stories = stories.sort(sortByMostRecent)
        break
      case 'Chronological':
        stories = stories.sort(sortByTimeline)
        break
      default:
        console.error('should not be here')
    }

    return stories
  }

  async function fetchAllStoriesForCollection(id) {
    let stories = await api.story().getAllByCollectionId(id, sortOrderLabel)
    stories = sortStories(stories)
    setAllStories(stories)
    setSelectedStories(stories.slice(0, NO_OF_STORIES_TO_FETCH))
    setShowProgressDialog(false)
  }

  async function fetchStoriesForSubCollectionWithTitle(id, title) {
    let stories = await api.story().getAllByCollectionIdAndSubCollectionTitle(id, title, sortOrderLabel)
    stories = sortStories(stories)
    setAllStories(stories)
    setSelectedStories(stories.slice(0, NO_OF_STORIES_TO_FETCH))
    setShowProgressDialog(false)
  }

  async function fetchCollection(collectionId) {
    const collection = await api.collection().getById(collectionId)
    setCollection(collection)
    setList([...defaultList, ...collection.subCollections])
    setShowProgressDialog(false)
  }

  useEffect(() => {
    if (collectionId) {
      setShowProgressDialog(true)
      fetchCollection(collectionId)
    }
  }, [collectionId])

  useEffect(() => {
    if (subCollection) {
      setSelected(subCollection.title)
    } else {
      setSelected('All')
    }
  }, [subCollection])

  useEffect(() => {
    if (!selected) return

    setAllStories([])
    setSelectedStories([])

    setShowProgressDialog(true)

    if (selected === 'All') {
      fetchAllStoriesForCollection(collectionId)
    } else {
      fetchStoriesForSubCollectionWithTitle(collectionId, selected)
    }

  }, [selected, collectionId])


  const handleSelect = (key) => {
    setSelected(key)
  }

  const handleFilterSelected = (filter) => {
    setShowProgressDialog(true)

    let allStoriesCopy = [...allStories]

    setSelectedStories([])
    setAllStories([])

    switch (filter) {
      case 'Recently Added':
        allStoriesCopy = allStoriesCopy.sort(sortByMostRecent)
        break
      case 'Chronological':
        allStoriesCopy = allStoriesCopy.sort(sortByTimeline)
        break
      default:
        console.error('should not be here')
    }

    setSelectedFilter(filter)

    setAllStories(allStoriesCopy)
    setSelectedStories(allStoriesCopy.slice(0, NO_OF_STORIES_TO_FETCH))
  }

  const sortByMostRecent = (storyOne, storyTwo) => {
    const keyA = new Date(storyOne.createdAt),
      keyB = new Date(storyTwo.createdAt)
    // Compare the 2 dates
    if (keyA < keyB) return 1
    if (keyA > keyB) return -1
    return 0
  }

  const sortByTimeline = (storyOne, storyTwo) => {
    const dateOne = getFormattedDate(storyOne)
    const dateTwo = getFormattedDate(storyTwo)

    if (dateOne < dateTwo) return -1
    if (dateOne > dateTwo) return 1
    return 0
  }

  const getFormattedDate = story => {
    const { startYear, startMonth, startDay } = story

    return getDate({ day: startDay, month: startMonth, year: startYear })
  }

  return (
    <>
      <div style={{ position: 'relative', height: 400 }}>
        <div
          className={classes.collectionImage}
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className={classes.collectionTitle}>
            {collection ? collection.title : ''}
          </div>
          <div className={classes.collectionDesc}>
            {collection ? collection.description : ''}
          </div>
          <div className={classes.gradient} />
          <div className={classes.scrollMenuRoot}>
            <div className={classes.subCollections} style={{ display: 'flex' }}>
              <SubCollections
                list={list}
                selected={selected}
                select={handleSelect}
              />
            </div>
            <Filters
              filters={filters}
              selectedFilter={selectedFilter}
              click={handleFilterSelected}
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: 100 }} />
      <Stories
        stories={selectedStories}
        showStory={showStory}
        collectionsPage
        orgId={exhibit?.orgId}
      />
      <ProgressDialog show={showProgressDialog} right />
    </>
  )
}

StoryFeedCollection.defaultProps = {
  collection: {
    coverPhoto: '',
  },
}
