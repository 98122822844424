// TODO: [P3MVP-495] if in mobile view the collection stories have a max height of 500px. This ends up calling the skip trigger
// if enough stories are loaded to cause the element to extend thus becoming scrollable.
// If, however, less content loads for the overflow not to occur, the trigger might never be called
import React, { useState, useEffect, useRef } from 'react'
import { makeStyles, Button } from '@material-ui/core'
import SubCollections from '../main/SubCollections'
import Story from './Story'
import NoStoriesFound from '@material-ui/icons/PhotoLibrary'
import DefaultCollectionCoverPhoto from './default_collection_cover.png'
import { NO_OF_STORIES_TO_FETCH } from '../Util'
import { useDebounce } from 'use-debounce'
import { isMobile } from 'react-device-detect'

import CollectionCoverPhoto from './CollectionCoverPhoto'
import api from '../exhibitApi'

const useStyles = makeStyles((theme) => {
  return {
    title: {
      marginLeft: '1%',
      position: 'absolute',
      zIndex: 1,
      textShadow: '0 2px 4px rgba(0,0,0,.4)',
      fontWeight: 700,
      letterSpacing: '-.8px',
      color: '#fff',
      [theme.breakpoints.up('xs')]: {
        fontSize: 20,
        bottom: 10,
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
        bottom: 32,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 32,
      },
    },
    gradient: {
      paddingTop: '15%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      opacity: 0.8,
      zIndex: 0,
      background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(0,0,0,1) 100%)',
    },
    subCollections: {
      backgroundColor: theme.palette.secondary.main,
    },
    storiesContainer: {
      flexWrap: 'wrap',
      margin: 10,
    },
    noStoriesRoot: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    noStoriesFound: {
      width: '5em',
      height: '5em',
    },
    viewButton: {
      zIndex: 1,
      position: 'absolute',
      bottom: 60,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      border: '4px solid #eee',
      padding: '5px 16px',
      fontSize: '80%',
      [theme.breakpoints.up('xs')]: {
        bottom: 35,
        border: '2px solid #eee',
        padding: '0 10px',
      },
    },
  }
})

const defaultList = [{ title: 'All' }]

export default function Collection(props) {
  const { app, collection, storyClicked, isLastCollection } = props

  const classes = useStyles()

  const [list, setList] = useState(defaultList)
  const [selected, setSelected] = useState('')
  const [clicked, setClicked] = useState(false)
  const [stories, setStories] = useState([])
  const [image, setImage] = useState('')
  const [allStories, setAllStories] = useState([])
  const [skip, setSkip] = useState(0)
  const [debouncedSkip] = useDebounce(skip, 1000)

  const mainRef = useRef(null)

  useEffect(() => {
    return () => {
      setSkip(0)
    }
  }, [])

  useEffect(() => {
    setSelected('All')
    const arr = []
    collection.subCollections.forEach((sub) => arr.push({ title: sub.title }))
    setList([...defaultList, ...arr])
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (collection.coverPhoto) {
      let width, height

      if (isMobile) {
        width = 750
        height = 750
      } else {
        width = 1000
        height = 1000
      }

      const link = api.image().imageUrl(collection.coverPhoto, width, height)
      setImage(link)
    } else {
      setImage(DefaultCollectionCoverPhoto)
    }
  }, [app, collection])

  useEffect(() => {
    if (skip > stories.length) {
      resetSkip(stories.length)
      return
    }
    const arr = []
    for (
      let index = skip;
      index < Math.min(allStories.length, skip + NO_OF_STORIES_TO_FETCH);
      index++
    ) {
      const story = allStories[index]
      arr.push(story)
    }
    setStories([...stories, ...arr])
    // eslint-disable-next-line
  }, [skip]);

  useEffect(() => {
    async function fetchAllStoriesForCollection(id) {
      const stories = await api.story().getAllByCollectionId(id)
      setAllStories(stories)
      setStories(stories.slice(0, NO_OF_STORIES_TO_FETCH))
    }

    async function fetchStoriesForSubCollectionWithTitle(id, title) {
      const stories = await api.story().getAllByCollectionIdAndSubCollectionTitle(id,title)
      setAllStories(stories)
      setStories(stories.slice(0, NO_OF_STORIES_TO_FETCH))
    }

    if (!selected) return

    if (selected === 'All') {
      fetchAllStoriesForCollection(collection._id)
    } else {
      fetchStoriesForSubCollectionWithTitle(collection._id, selected)
    }

  }, [api, selected, collection])

  useEffect(() => {
    // Only scroll to bottom if it's the last collection
    // if not done this way, user will click cover image and
    // not know that content has filled up beneath collection cover photo
    if (clicked && isLastCollection && mainRef.current) {
      mainRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [clicked, isLastCollection])

  const resetSkip = (newSkip) => {
    setSkip(newSkip)
  }

  const handleClick = () => {
    setClicked(!clicked)
  }

  const handleSelect = (key) => {
    setSelected(key)
  }

  const filterStories = (story) => {
    if (story.status !== 'Published') {
      return false
    }
    if (selected === 'All') return true
    return true
  }

  const handleScroll = (e) => {
    // strict equality doesn't always work; introducing delta of 1 for such cases.
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      ) <= 1
    if (bottom) {
      setSkip((prevSkip) =>
        Math.min(
          prevSkip + NO_OF_STORIES_TO_FETCH,
          debouncedSkip + NO_OF_STORIES_TO_FETCH
        )
      )
    }
  }


  return (
    <>
      <div
        style={{
          position: 'relative',
          paddingTop: '31%',
          paddingBottom: '2%',
        }}
        onClick={handleClick}
      >
        <CollectionCoverPhoto
          image={image}
          title={collection.title} />

        <div className={classes.title}>{collection.title}</div>
        <Button variant="outlined" className={classes.viewButton}>
          {clicked ? 'Close' : 'View'}
        </Button>
        <div className={classes.gradient} />
      </div>
      <div
        className={classes.subCollections}
        style={{ display: clicked ? 'flex' : 'none' }}
      >
        <SubCollections list={list} selected={selected} select={handleSelect} />
      </div>
      <div
        onScroll={handleScroll}
        ref={mainRef}
        className={classes.storiesContainer}
        style={{
          height: isMobile ? 300 : 1000,
          overflowY: 'scroll',
          display: clicked ? 'flex' : 'none',
          justifyContent: stories.length === 0 ? 'center' : 'unset', // to center align `No Stories` Message
          flexDirection: 'row',
        }}
      >
        {stories.length === 0 && (
          <div className={classes.storyRoot}>
            <div className={classes.noStoriesRoot}>
              <NoStoriesFound className={classes.noStoriesFound} />
              <div style={{ fontSize: 'larger' }}>No Stories Found</div>
            </div>
          </div>
        )}
        {stories.length > 0 &&
          stories
            .filter(filterStories)
            .map((story) => (
              <Story
                key={story._id}
                story={story}
                click={storyClicked} />
            ))}
      </div>
    </>
  )
}
