import React from 'react'
import { Route } from 'react-router-dom'
import StoryFeed from './StoryFeed'
import Map from './Map'
import StoryPage from '../StoryPage'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import SearchPage from './SearchPage'

const drawerWidth = 240

const useStyles = makeStyles((theme) => {
  return {
    main: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    mainShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    },
  }
})

export default function Main(mainProps) {
  const {
    app,
    appId,
    drawerOpen,
    viewCollectionClicked,
    storyPageTagClick,
    resetSearch,
    exitedStoryFeedCollectionPage,
    resetSkip,
    moveToTop,
    skip
  } = mainProps

  const classes = useStyles()

  const handleShowStory = (storyId) => {
    mainProps.history.push(`${mainProps.match.url}/story/${storyId}`)
  }


  return (
    <div
      className={clsx(classes.main, {
        [classes.mainShift]: drawerOpen,
      })}
    >
      <Route
        path={mainProps.match.url}
        render={(props) => (
          <StoryFeed
            {...props}
            {...mainProps}
            showStory={handleShowStory}
            viewCollectionClicked={viewCollectionClicked}
            exitedStoryFeedCollectionPage={exitedStoryFeedCollectionPage}
            resetSkip={resetSkip}
          />
        )}
      />

      <Route
        exact
        path={`${mainProps.match.url}/art-map`}
        render={(props) => (
          <Map
            {...props}
            {...mainProps}
            showStory={handleShowStory}
            viewStoryClicked={(story) => handleShowStory(story._id)}
          />
        )}
      />

      <Route
        exact
        path={`${mainProps.match.url}/story/:storyId`}
        render={(props) => (
          <StoryPage
            {...props}
            {...mainProps}
            app={app}
            tagClick={storyPageTagClick}
            moveToTop={moveToTop}
          />
        )}
      />
      <Route
        exact
        path={`${mainProps.match.url}/search`}
        render={(props) => (
          <SearchPage
            {...props}
            appId={appId}
            showStory={handleShowStory}
            reset={resetSearch}
            skip={skip}
          />
        )}
      />
    </div>
  )
}
