import React from 'react'
import StoryFeedMain from './StoryFeedMain'
import { Route } from 'react-router-dom'
import StoryFeedCollection from './StoryFeedCollection'

export default function StoryFeed(storyFeedProps){

    const { viewCollectionClicked, exitedStoryFeedCollectionPage, resetSkip } = storyFeedProps

    return (
        <>
            <Route exact path={storyFeedProps.match.url}
                render={(props) =>
                    <StoryFeedMain
                        {...props}
                        {...storyFeedProps}
                        viewCollectionClicked={viewCollectionClicked}  /> } />

            <Route exact path={`${storyFeedProps.match.url}/collection/:collectionId`}
                render={(props) =>
                    <StoryFeedCollection
                        {...props}
                        {...storyFeedProps}
                        pageExited={exitedStoryFeedCollectionPage}
                        resetSkip={resetSkip}/>}/>

        </>
    )
}