
// TODO : zoom in on first story

import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import MapsPin from './maps_pin.png'
import { makeStyles } from '@material-ui/styles'



const useStyles = makeStyles({
  img: {
      height: 50, position: 'relative', cursor: 'pointer'
  }
})

const Marker = (props) => {

  const { click } = props
  const classes = useStyles()

  return (
      <img onClick={click} alt="Map Pin" src={MapsPin} className={classes.img}/>
  )
}

export class GoogleMaps extends Component {

  state = {
    location: undefined,
    center: undefined
  }


  componentDidUpdate(nextProps){
    if(!nextProps.location && this.props.location){
      this.setState({
        location: this.props.location
      })
    }

    if(!nextProps.center && this.props.center){
      this.setState({
        center: this.props.center
      })
    }

  }
  

  render() {

    const { location, mapStyle, app }= this.props
      
    return (
        <div style={{height: 300, width: '100%', margin: '30px 0'}}>
            <GoogleMapReact
                center={this.state.center}
                defaultCenter={app.mapCenterAddressCoords}
                zoom={app.mapZoom}
                options={{ styles: mapStyle, scrollwheel: false }}>
                  {this.state.location && <Marker lat={location.lat} lng={location.lng} />}
            </GoogleMapReact>
        </div>
    )
  }
}


export default GoogleMaps