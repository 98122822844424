import React, { useEffect, useState } from 'react'
import { withRouter, useLocation } from 'react-router-dom'
import Stories from './Stories'
import ProgressDialog from '../CollectionsPage/ProgressDialog'
import api from '../exhibitApi'


function SearchPage(props) {
  const { showStory, appId, reset, skip } = props

  const [stories, setStories] = useState([])
  const [loading, setLoading] = useState(false)
  const [offset, setOffset] = useState(0)
  const [fetched, setFetched] = useState(false)
  const location = useLocation().search

  async function search() {
    const query = new URLSearchParams(location)
    const value = query.get('value')
    const tag = query.get('tag')

    if (value) {
      setLoading(true)
      searchStories(value)
      setFetched(true)
    } else if (tag) {
      setLoading(true)
      searchTags(tag)
      setFetched(true)
    } else {
      setStories([])
      reset()
    }
  }

  async function searchStories(term) {
    const searchedStories = await api.story().search({ appId, term, offset })
    setStories([...stories, ...searchedStories])

    setLoading(false)
  }

  async function searchTags(tag) {
    const searchedStories = await api.story().searchTags({ appId, tag })
    setStories([...stories, ...searchedStories])

    setLoading(false)
  }

  useEffect(() => {
    setOffset(stories.length)
  }, [skip])

  useEffect(() => {
    if (fetched === false && stories.length === 0) search()
  }, [stories])

  useEffect(() => {
    setLoading(true)
    setFetched(false)
    setStories([])
    setOffset(0)
  }, [location])


  useEffect(() => {
    search()
  }, [api, appId, offset])


  return (
    <>
      <ProgressDialog show={loading} right />
      <Stories stories={stories} showStory={showStory} loading={loading} searchPage />
    </>
  )
}

export default withRouter(SearchPage)
