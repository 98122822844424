import React, { useEffect, useState } from 'react'
import GoogleMapsSearch from './GoogleMapsSearch'
import SearchCard from './SearchCard'
import ResultsList from './ResultsList'
import ProgressDialog from '../CollectionsPage/ProgressDialog'
import Snackbar from '../Snackbar'
import { isMobile } from 'react-device-detect'
import api from '../exhibitApi'

const defaultCenter = {
  lat: 38.267153,
  lng: -97.7430608
}


function getMapCenter(app){
  if(app.mapCenterAddressCoords){
    return app.mapCenterAddressCoords
  }else{
    return defaultCenter
  }
}

function removeStoriesWithNoLocation(stories){
  const res = []
  for (let index = 0; index < stories.length; index++) {
    const story = stories[index]
    const location = story.location
    if(location.lat && location.lng) res.push(story)
  }
  return res
}

function getDisciplines(collections){
  const discipline = collections.find(c => c.title === 'Discipline')
  return discipline.subCollections.map(sub => sub.title)
}

export default function AdvancedSearch(props){
  const { app, viewStoryClicked } = props
  const mapStyleName = app.mapStyle

    const [mapStyle, setMapStyle] = useState(undefined)
    const [stories, setStories] = useState([])
    const [allStories, setAllStories] = useState([])
    const [data, setData] = useState([])
    const [showProgress, setShowProgress] = useState(false)
    const [snackBarProps, setSnackbarProps] = useState({ open: false, message: '' })
    const [searchStories, setSearchStories] = useState([])
    const [mapCenter, setMapCenter] = useState(undefined)
    const [mapZoom, setMapZoom] = useState(undefined)
    const [resetMap, setResetMap] = useState(false)
    const [collections, setCollections] = useState([])
    const [selectedCollection, setSelectedCollection] = useState('')
    const [disciplines, setDisciplines] = useState([])
    const [selectedDiscipline, setSelectedDiscipline] = useState('')


  useEffect(() => {
    async function fetchMapStyle(name){
      const style = await api.mapStyle().getByName(name)
      setMapStyle(style)
    }

    if(!app) return

    fetchMapStyle(mapStyleName)

    setShowProgress(true)

    fetchStories(app._id)
    setMapCenter(getMapCenter(app))
    fetchCollections(app._id)

  }, [app, mapStyleName])


  const fetchCollections = async (id) => {
    const collections = await api.collection().getByExhibitId(id)
    setCollections(collections)

    const disciplines = getDisciplines(collections)
    setDisciplines(disciplines)
  }

  const fetchStories = async (id) => {
    const stories = await api.story().getAllByExhibitId(id)
    setStories(stories)
    setAllStories(stories)
    const data = getUniqueLocations(stories)
    setData(data)
    setShowProgress(false)
  }

    const getUniqueLocations = (stories) => {

        const arr = []

        for (let index = 0; index < stories.length; index++) {
          const story = stories[index]
          const location = story.location
          if(locationNull(location)) continue
          const locationIndex = locationAlreadyExists(arr, location)
          if(locationIndex === -1){ // location does not exist
            const storiesArray = []
            storiesArray.push(story)
            arr.push({
              location: {...location},
              stories: storiesArray
            })
          }else{
            arr[locationIndex].stories.push(story)
          }
        }

        return arr

      }

      const locationNull = (location) => {
        return location.lat === null || location.lat === undefined ||
        location.lng === null|| location.lng === undefined
      }

      const locationAlreadyExists = (arr, location) => {
        for (let index = 0; index < arr.length; index++) {
          const l = arr[index].location
          if(l.lat === location.lat && l.lng === location.lng){
            return index
          }
        }
        return -1
      }

      const handleOnDragEnd = ({bounds}) => {

        const stories = allStories.filter(story => {
          const location = story.location
          return location.lat <= bounds.nw.lat &&
            location.lat >= bounds.sw.lat &&
            location.lng <= bounds.ne.lng &&
            location.lng >= bounds.nw.lng
        })

        setStories(stories)
    }

    const handleSearch = async (title, artist, keywords, collectionTitle, discipline, sortBy) => {
      title = title.trim()
      artist = artist.trim()

      let collectionId

      if(collectionTitle){
        const collection = collections.find(c => c.title === collectionTitle)
        collectionId = collection._id
      }

      setShowProgress(true)
      setSearchStories([])
      setData([])

      keywords = keywords.split(',').map(keyword => keyword.trim())
      let searchStories = await api.story().searchBy({appId: app._id, title, artist, keywords, collectionId, discipline, sortBy})

      if(searchStories.length === 0){
        setSnackbarProps({ open: true, message: 'No stories found' })
      }else{
        searchStories = removeStoriesWithNoLocation(searchStories)
        setSearchStories(searchStories)
        const data = getUniqueLocations(searchStories)
        setData(data)
      }

      setShowProgress(false)
    }

    const handleCloseSnackBar = () => {
      setSnackbarProps({ open: false, message: '' })
    }

    const handleResultItemClicked = (passedStory) => {
      if(isMobile){
        viewStoryClicked(passedStory)
      }else{
        const story = allStories.find(s => s._id === passedStory._id)
        setMapCenter(story.location)
        setMapZoom(15)
      }

    }

    const handleReset = () => {
      const data = getUniqueLocations(allStories)
      setData(data)
      setMapZoom(undefined)
      setSearchStories([])
      setSelectedCollection('')
      setSelectedDiscipline('')
      setMapZoom(app.mapZoom)
      setMapCenter(getMapCenter(app))
      setResetMap(true)

    }

    const handleChangeCollection = (e) => {
      const value = e.target.value
      setSelectedCollection(value)
    }

    const handleChangeDiscipline = (e) => {
      const value = e.target.value
      setSelectedDiscipline(value)
    }


    return (
        <>

        {!isMobile && <GoogleMapsSearch
            app={app}
            stories={stories}
            data={data}
            onDragend={handleOnDragEnd}
            viewStoryClicked={viewStoryClicked}
            mapStyle={mapStyle}
            center={mapCenter}
            storyZoom={mapZoom}
            reset={resetMap} />  }

        <SearchCard
          search={handleSearch}
          reset={handleReset}
          collections={collections}
          disciplines={disciplines}
          selectedCollection={selectedCollection}
          selectedDiscipline={selectedDiscipline}
          changeCollection={handleChangeCollection}
          changeDiscipline={handleChangeDiscipline}
          isMobile={isMobile} />

        <ResultsList isMobile={isMobile} stories={searchStories} resultItemClicked={handleResultItemClicked}/>

        <ProgressDialog show={showProgress} left />

        <Snackbar open={snackBarProps.open} message={snackBarProps.message} close={handleCloseSnackBar}/>

        </>
    )
}
