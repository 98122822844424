import React from 'react'


export default function Text(props){

    const { asset } = props

    return (
        <div dangerouslySetInnerHTML={{ __html: asset.text }} />
    )
}