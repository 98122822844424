import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import SidePanelItem from './SidePanelItem'
import CollectionButton from './CollectionButton'
import HomeIcon from '../images/home-512.png'
import ArtMapIcon from '../images/art-map-512.png'
import WalkingTourBrochureIcon from '../images/books-512.png'
import NeedHelpIcon from '../images/help-512.png'
import CollectionsIcon from '../images/collections-512.png'
import AboutIcon  from '../assets/about_icon.svg'
import OurProgramsIcon  from '../images/our_programs_icon.svg'
import OurResourcesIcon  from '../images/resources_icon.svg'
import { DRAWER_ITEM_TEXT, HEADER_ITEM_TEXT } from '../Util'



const drawerWidth = 240


const useStyles = makeStyles({
  fullList: {
    width: 'auto',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: 120,
    height: 'calc(100% - 190px)', // 190 because of footer
    zIndex: 900, // light box has a zIndex of 1200 so this value should be anything less than that,
    background: '#EEF1F3',
    borderColor: 'transparent',
  },
})

export default function SidePanelDrawer(props) {

  const { app, open, isMobile, toggle, collections, clickCollection, subCollectionClick, drawerItemClick, maxWidth1025px } = props


  const classes = useStyles()
  

  return (
    <div>
      <Drawer 
        onClose={toggle(false)}  
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}>
      <div
        style={{padding: '0 20px'}}
        role="presentation"
        onClick={toggle(isMobile ? false : true)}>
        <List>
          <SidePanelItem click={drawerItemClick} text={DRAWER_ITEM_TEXT.EXPLORE} Icon={HomeIcon} iconHeight={22} />
          <SidePanelItem click={drawerItemClick} text={DRAWER_ITEM_TEXT.ART_MAP} Icon={ArtMapIcon} iconHeight={18} />
          <SidePanelItem click={drawerItemClick} text={DRAWER_ITEM_TEXT.WALKING_TOUR_BROCHURE} Icon={WalkingTourBrochureIcon}  iconHeight={22} />
          <SidePanelItem click={drawerItemClick} text={DRAWER_ITEM_TEXT.CONTACT_US} Icon={NeedHelpIcon} iconHeight={22}/>
          <SidePanelItem click={drawerItemClick} text={DRAWER_ITEM_TEXT.COLLECTIONS} Icon={CollectionsIcon} iconHeight={22}/>
          {maxWidth1025px && (
            <>
            <SidePanelItem click={drawerItemClick} text={HEADER_ITEM_TEXT.ABOUT} Icon={AboutIcon} iconHeight={22} />
            <SidePanelItem click={drawerItemClick} text={HEADER_ITEM_TEXT.OUR_PROGRAMS} Icon={OurProgramsIcon} iconHeight={22} />
            <SidePanelItem click={drawerItemClick} text={HEADER_ITEM_TEXT.RESOURCES} Icon={OurResourcesIcon} iconHeight={22} />
            </>
          )}
        </List>
        <Divider />
        <List>
        {collections.map((collection, index) => (
          <li key={index} style={{margin: '10px 0'}}>
              <CollectionButton 
                app={app}
                collection={collection} 
                clickCollection={clickCollection}
                subCollectionClick={subCollectionClick} />
          </li>                  
        ))}
        </List>
    </div>
      </Drawer>
    </div>
  )
}
