import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => {
    return {
        root: {
            padding: '10px 18px 26px 18px',
            fontSize: '105.01%',
            boxSizing: 'border-box',
            color: '#999'
        },
        tag: {
            color: theme.palette.primary.main,
            marginRight: 16,
            cursor: 'pointer'
        }
    }
   
})

export default function Tags(props){

    const { tags, click } = props

    const classes = useStyles()

 return (
     <div className={classes.root}>
        <div>Tags</div>
        {tags.map(tag => (
            // eslint-disable-next-line
            <a key={tag._id} className={classes.tag} onClick={() => click(tag)}>#{tag.value}</a>
        ))}
        </div>
 )   
}