import { configureStore } from '@reduxjs/toolkit'
import exhibitReducer from './exhibitSlice'
import storiesReducer from './storiesSlice'


export default configureStore({
  reducer: {
    stories: storiesReducer,
    exhibit: exhibitReducer
  },
})
