import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import StoriesList from './StoriesList'


export default function Stories(props){

    const { stories, showStory } = props

    const theme = useTheme()

    return (
        <>
            <div style={{
            padding: theme.spacing(3),
            paddingBottom: 0,
                fontWeight: 900,
                fontSize: 22,
                lineHeight: '1.2',
                letterSpacing: '-.7px',
            color: '#333'}}>
            Discover:
        </div>
            <StoriesList
                stories={stories}
                showStory={showStory} />
        </>

    )
}