import { createSlice } from '@reduxjs/toolkit'
import { storyMapSelector } from './storiesSelector'
import api from '../exhibitApi'

export const storiesSlice = createSlice({
  name: 'stories',
  initialState: {
    allStoriesLoaded: false,
    allStories: []
  },
  reducers: {
    setAllStoriesLoaded: (state, action) => {
      state.allStoriesLoaded = action.payload
    },
    setAllStories: (state, action) => {
      state.allStories = action.payload
    }
  },
})

export const { setAllStoriesLoaded, setAllStories } = storiesSlice.actions

export const loadStoriesForApp = (appId) => async (dispatch, getState) => {
    const state = getState()
    if(state.stories.allStoriesLoaded) return

    const stories = await api.story().getAllByExhibitId(appId)
    dispatch(setAllStoriesLoaded(true))
    dispatch(setAllStories(stories))
}

export const allAppStoriesForMap = state => storyMapSelector(state)
export const allStoriesForApp = state => state.stories.allStories
export const allStoriesForAppLoaded = state => state.stories.allStoriesLoaded

export default storiesSlice.reducer
