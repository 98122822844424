import React from 'react'
import { useTheme } from '@material-ui/styles'
import ExploreByMapStaticImage from './ExploreByMapStaticImage.jpg'


export default function ExploreByMap(props){

    const { exploreClicked } = props

    const theme = useTheme()

    return (
        <>
        <div style={{
            padding: `0 ${theme.spacing(3)}px`,
            fontWeight: 900,
            fontSize: 22,
            lineHeight: 1.2,
            letterSpacing: '-.7px',
            color: '#333',
            marginBottom: 10
        }}>Art Map: What will you experience next?</div>
  
            <div className={'googleMapsRoot'}  style={{position: 'relative'}}>

              <img className={'exploreByMapImage'} src={ExploreByMapStaticImage} alt="Explore By Map" />

              <div style={{
                position: 'absolute', 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 50,
                width: 130,
                bottom: '15%', 
                backgroundColor: '#FF5368',
                color: 'white',
                borderRadius: 25,
                fontWeight: 700,
                cursor: 'pointer',
                left: '5%'}} onClick={exploreClicked}>Explore</div>

            </div>
            
        </>
    )
}
